import React, {
  useState,
  useEffect,
  FormEvent,
  ChangeEvent,
  useMemo,
  useCallback,
} from 'react'
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap'
import { isEmployee } from '../hooks/useIsLoggiedIn.hook'
import {
  Country,
  Employee,
  EmployeeWithRoles,
  emptyEmployee,
  Role,
} from '../utils/LoginDTOS'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'
import { useCountriesContext } from './context/getCountries.context'
import {
  updateImage,
  updatePassword,
  UpdatePasswordDTO,
  updateUserData,
  UpdateUserDataDTO,
} from '../utils/LoginUtils'
import { useEmployeesContext } from './context/getEmployees.context'
import { useTranslation } from 'react-i18next'
import { useToast } from './context/ToastContext.context'
import { ToastTypes } from '../utils/ToastUtils'

export interface UpdatePasswordDataDTO {
  currentPassword: string
  newPassword: string
  newPasswordDuplicate: string
}

const ProfilePage = () => {
  const { addToast } = useToast()

  const [formValues, setFormValues] = useState<Employee>(emptyEmployee)
  const [updatePasswordData, setUpdatePasswordData] =
    useState<UpdatePasswordDataDTO>({} as UpdatePasswordDataDTO)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editPasswordMode, setEditPasswordMode] = useState<boolean>(false)
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })
  const [updatePasswordError, setUpdatePasswordError] = useState<string>('')

  const { t } = useTranslation()

  const { currentUser, refreshCurrentUserInCookies } = useIsLoggedInContext()
  const { countries } = useCountriesContext()
  const { employees, fetchEmployees } = useEmployeesContext()

  const currentEmployeeWithRoles = useMemo<EmployeeWithRoles>(() => {
    return employees.filter((empl) => empl?.id === currentUser?.id)[0]
  }, [employees, currentUser])

  useEffect(() => {
    refreshFormValues()
  }, [currentUser])

  const refreshFormValues = useCallback(() => {
    if (isEmployee(currentUser)) {
      setFormValues({ ...currentUser })
    }
  }, [currentUser])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name === 'nationality') {
      setFormValues({
        ...formValues,
        [name]: countries.filter((country) => country.id === +value)[0],
      })
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      })
    }
  }

  function getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file) {
      try {
        const formData = new FormData()

        const employeeId = currentUser?.id ?? 0

        formData.append('id', employeeId.toString())
        formData.append('image', file)

        const response = await updateImage(formData)

        if (typeof response === 'string') {
          addToast(t('FAILURE'), t(response), ToastTypes.ERROR)
        } else {
          addToast(
            t('SUCCESS'),
            t('PROFILE_IMAGE_HAS_BEEN_CHANGED'),
            ToastTypes.DEFAULT
          )
        }
        await fetchEmployees()
        refreshCurrentUserInCookies({
          ...currentEmployeeWithRoles,
          image: await getBase64(file),
        })
      } catch (error) {
        console.error('Error updating image:', error)
      }
    }
  }

  const onSubmitPasswordChange = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setUpdatePasswordError('')
    if (
      updatePasswordData.newPassword !== updatePasswordData.newPasswordDuplicate
    ) {
      setUpdatePasswordError('NEW_PASSWORDS_ARE_NOT_THE_SAME')
      return
    }

    if (updatePasswordData.newPassword.length < 8) {
      setUpdatePasswordError('PASSWORD_LENGTH_MUST_BE_GREATER_THAN_EIGHT')
      return
    }

    const updateDTO: UpdatePasswordDTO = {
      userId: currentUser?.id ?? 0,
      oldPassword: updatePasswordData.currentPassword,
      newPassword: updatePasswordData.newPassword,
    }
    const response = await updatePassword(updateDTO)
    if (typeof response === 'string') {
      addToast(t('FAILURE'), t(response), ToastTypes.ERROR)
    } else {
      addToast(t('SUCCESS'), t('PASSWORD_HAS_BEEN_CHANGED'), ToastTypes.DEFAULT)
      setEditPasswordMode((prev) => !prev)
      setUpdatePasswordData({} as UpdatePasswordDataDTO)
    }
    fetchEmployees()
  }

  const updateCookies = useCallback(
    (updatedData: UpdateUserDataDTO | { image: string }) => {
      refreshCurrentUserInCookies({
        ...currentEmployeeWithRoles,
        ...updatedData,
      })
    },
    [currentEmployeeWithRoles]
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const updatedData: UpdateUserDataDTO = {
      id: currentEmployeeWithRoles.id ?? 0,
      firstName: formValues.firstName ?? '',
      middleName: formValues.middleName ?? '',
      lastName: formValues.lastName ?? '',
      email: formValues.email ?? '',
      nationality: formValues.nationality ?? undefined,
      // image: formValues?.image?.split(',')[1],
    }

    try {
      const response = await updateUserData(updatedData)

      if (typeof response === 'string') {
        setEditMode(false)
        addToast(
          t('SUCCESS'),
          t('USER_DATA_HAS_BEEN_UPDATED'),
          ToastTypes.DEFAULT
        )
      } else {
        addToast(
          t('FAILURE'),
          t('USER_DATA_HAS_NOT_BEEN_UPDATED'),
          ToastTypes.ERROR
        )
      }
      await fetchEmployees()
      updateCookies(updatedData)
    } catch (error) {
      console.error('Error updating profile', error)
    }
  }

  const imageSrc: string = useMemo(() => {
    if (formValues.image === undefined) return '/img/placeholder-user.png'

    return formValues.image.split(',')[1] === 'null'
      ? '/img/placeholder-user.png'
      : formValues.image
  }, [formValues.image])

  return (
    <Container className='page'>
      <Row>
        <Col sm={3}>
          <Image style={{ width: '100%' }} src={imageSrc} roundedCircle fluid />
          {currentEmployeeWithRoles && currentEmployeeWithRoles.roles && (
            <Row>
              <Col sm={12}>
                {t('YOUR_ROLES_DOTS')}{' '}
                {currentEmployeeWithRoles.roles
                  .map((role: Role) => role.name)
                  .join(', ')}
              </Col>
            </Row>
          )}
          <Form>
            <Form.Group as={Col} controlId='formGridImage' className='mt-4'>
              <Form.Label>{t('PROFILE_IMAGE')}</Form.Label>
              <Form.Control
                type='file'
                name='image'
                onChange={handleFileChange}
              />
              <Form.Label style={{ color: 'red' }}>
                {t('BE_CAREFUL_IMAGE_SIZE_HAVE_TO_BE_SMALL')}
              </Form.Label>
            </Form.Group>
          </Form>
        </Col>
        <Col sm={9}>
          <Container fluid>
            <h1>{t('USER_DATA')}</h1>
            <Form onSubmit={handleSubmit}>
              <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridFirstName'>
                  <Form.Label>{t('FIRST_NAME')}</Form.Label>
                  <Form.Control
                    disabled={!editMode}
                    type='text'
                    name='firstName'
                    placeholder={t('ENTER_FIRST_NAME')}
                    value={formValues.firstName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.firstName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId='formGridMiddleName'>
                  <Form.Label>{t('MIDDLE_NAME')}</Form.Label>
                  <Form.Control
                    disabled={!editMode}
                    type='text'
                    name='middleName'
                    placeholder={t('ENTER_MIDDLE_NAME')}
                    value={formValues.middleName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridLastName'>
                  <Form.Label>{t('LAST_NAME')}</Form.Label>
                  <Form.Control
                    disabled={!editMode}
                    type='text'
                    name='lastName'
                    placeholder={t('ENTER_LAST_NAME')}
                    value={formValues.lastName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.lastName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridEmail'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled={!editMode}
                    type='email'
                    name='email'
                    placeholder={t('ENTER_EMAIL')}
                    value={formValues.email}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridNationality'>
                  <Form.Label>{t('NATIONALITY')}</Form.Label>
                  <Form.Control
                    disabled={!editMode}
                    as='select'
                    name='nationality'
                    value={formValues?.nationality?.id}
                    onChange={handleInputChange}
                  >
                    <option value=''>{t('SELECT_NATIONALITY')}</option>
                    {countries.map((country: Country) => (
                      <option value={country.id}>{country.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {editMode && (
                <>
                  <Button
                    variant='danger'
                    onClick={() => {
                      setEditMode((prev) => !prev)
                      refreshFormValues()
                    }}
                  >
                    {t('CANCEL_EDITING')}
                  </Button>
                  <Button variant='primary' type='submit' className='m-3'>
                    {t('UPDATE_PROFILE')}
                  </Button>
                </>
              )}

              {!editMode && !editPasswordMode && (
                <div className='d-flex flex-column'>
                  <Button
                    variant='primary'
                    onClick={() => setEditMode((prev) => !prev)}
                    className='m-2'
                    style={{ maxWidth: '20rem' }}
                  >
                    {t('UPDATE_PROFILE')}
                  </Button>
                  <Button
                    variant='primary'
                    onClick={() => setEditPasswordMode((prev) => !prev)}
                    className='m-2'
                    style={{ maxWidth: '20rem' }}
                  >
                    {t('UPDATE_PASSWORD')}
                  </Button>
                </div>
              )}
            </Form>
            {editPasswordMode && (
              <Form onSubmit={onSubmitPasswordChange}>
                <Row>
                  <Form.Group as={Col} controlId='formGridCurrentPassword'>
                    <Form.Label>{t('CURRENT_PASSWORD')}</Form.Label>
                    <Form.Control
                      placeholder={t('ENTER_CURRENT_PASSWORD')}
                      value={updatePasswordData.currentPassword}
                      onChange={(e) => {
                        setUpdatePasswordData((prev) => ({
                          ...prev,
                          currentPassword: e.target.value,
                        }))
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId='formGridPassword'>
                    <Form.Label>{t('PASSWORD')}</Form.Label>
                    <Form.Control
                      placeholder={t('ENTER_NEW_PASSWORD')}
                      value={updatePasswordData.newPassword}
                      onChange={(e) => {
                        setUpdatePasswordData((prev) => ({
                          ...prev,
                          newPassword: e.target.value,
                        }))
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='formGridPasswordConfirm'>
                    <Form.Label>{t('PASSWORD_CONFIRMATION')}</Form.Label>
                    <Form.Control
                      placeholder={t('ENTER_PASSWORD_CONFIRMATION')}
                      value={updatePasswordData.newPasswordDuplicate}
                      onChange={(e) => {
                        setUpdatePasswordData((prev) => ({
                          ...prev,
                          newPasswordDuplicate: e.target.value,
                        }))
                      }}
                    />
                  </Form.Group>
                  {updatePasswordError.length > 0 && (
                    <p style={{ color: 'red' }}>{t(updatePasswordError)}</p>
                  )}
                  <Row>
                    <Col>
                      <Button
                        variant='danger'
                        onClick={() => {
                          setEditPasswordMode((prev) => !prev)
                          setUpdatePasswordData({} as UpdatePasswordDataDTO)
                        }}
                      >
                        {t('CANCEL_EDITING')}
                      </Button>
                      <Button variant='primary' type='submit' className='m-3'>
                        {t('UPDATE_PASSWORD')}
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default ProfilePage
