import React, { useState, useMemo } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  DropdownButton,
  Dropdown,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Country, Employee, Project } from '../utils/LoginDTOS'
import {
  createProject,
  deleteProject,
  updateInvoicingDataProject,
  updateProjectData,
} from '../utils/ProjectUtils'
import { ToastTypes } from '../utils/ToastUtils'
import { useCountriesContext } from './context/getCountries.context'
import { useEmployeesContext } from './context/getEmployees.context'
import { useProjectsContext } from './context/getProjects.context'
import { useToast } from './context/ToastContext.context'
import CreateNewProjectModal from './modals/CreateNewProjectModal'
import UpdateProjectInvoicingDataModal from './modals/UpdateProjectInvoicingDataModal'

const HandleProjectPage = () => {
  const { projects, fetchProjects } = useProjectsContext()
  const { countries } = useCountriesContext()
  const { employees } = useEmployeesContext()

  const { t } = useTranslation()

  const { addToast } = useToast()

  const [isCreatingModalVisible, setIsCreatingModalVisible] =
    useState<boolean>(false)
  const [isUpdatingInvoicingDataVisible, setIsUpdatingInvoicingDataVisible] =
    useState<boolean>(false)

  const [updatingProject, setUpdatingProject] = useState<Project>({} as Project)

  const projectsWithoutVacationAndIllness = useMemo(() => {
    return projects.filter(
      (project: Project) =>
        project.name !== 'Vacations' && project.name !== 'Illness'
    )
  }, [projects])

  const handleUpdateProjectInfo = async () => {
    const response = await updateProjectData(updatingProject)
    if (typeof response !== 'string') {
      addToast(
        t('SUCCESS'),
        t('PROJECT_DATA_HAS_BEEN_CHANGED'),
        ToastTypes.DEFAULT
      )
    } else {
      addToast(
        t('FAILURE'),
        t('PROJECT_DATA_HAS_NOT_BEEN_CHANGED'),
        ToastTypes.ERROR
      )
    }
    fetchProjects()
    setUpdatingProject({} as Project)
  }

  const handleCreateProject = async (project: Project) => {
    const response = await createProject(project)
    if (typeof response !== 'string') {
      addToast(t('SUCCESS'), t('PROJECT_HAS_BEEN_CREATED'), ToastTypes.DEFAULT)
    } else {
      addToast(
        t('FAILURE'),
        t('PROJECT_HAS_NOT_BEEN_CREATED'),
        ToastTypes.ERROR
      )
    }
    fetchProjects()
    setUpdatingProject({} as Project)
  }

  const handleUpdateIncoicingDataProject = async (project: Project) => {
    const response = await updateInvoicingDataProject(project)
    if (typeof response !== 'string') {
      addToast(
        t('SUCCESS'),
        t('PROJECT_INVOICING_DATA_HAS_BEEN_UPDATED'),
        ToastTypes.DEFAULT
      )
    } else {
      addToast(
        t('FAILURE'),
        t('PROJECT_INVOICING_DATA_HAS_NOT_BEEN_UPDATED'),
        ToastTypes.ERROR
      )
    }
    fetchProjects()
    setUpdatingProject({} as Project)
  }

  const handleDeleteProject = async (project: Project) => {
    const formData = new FormData()

    const projectId = project.id ?? 0

    formData.append('projectId', projectId.toString())

    const response = await deleteProject(formData)
    if (typeof response !== 'string') {
      addToast(t('SUCCESS'), t('PROJECT_HAS_BEEN_DELETED'), ToastTypes.DEFAULT)
    } else {
      addToast(
        t('FAILURE'),
        t('PROJECT_HAS_NOT_BEEN_DELETED'),
        ToastTypes.ERROR
      )
    }
    fetchProjects()
  }

  return (
    <Container className='page'>
      <CreateNewProjectModal
        visible={isCreatingModalVisible}
        toggleVisible={() => setIsCreatingModalVisible((prev) => !prev)}
        onSubmit={handleCreateProject}
      />
      <UpdateProjectInvoicingDataModal
        visible={isUpdatingInvoicingDataVisible}
        updatingProject={updatingProject}
        onCancel={() => {
          setUpdatingProject({} as Project)
          setIsUpdatingInvoicingDataVisible((prev) => !prev)
        }}
        onSubmit={handleUpdateIncoicingDataProject}
        showBankAccount={false}
      />
      <Row className='mb-4'>
        <Col>
          <Button
            variant='warning'
            onClick={() => setIsCreatingModalVisible((prev) => !prev)}
          >
            {t('CREATE_NEW_PROJECT')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('PROJECT_ID')}</th>
                <th>{t('NAME')}</th>
                <th>{t('DESCRIPTION')}</th>
                <th>{t('IS_INTERNAL')}</th>
                <th>{t('ORIGIN_COUNTRY')}</th>
                <th>{t('PROJECT_MANAGER')}</th>
                <th>{t('SETTINGS')}</th>
              </tr>
            </thead>
            <tbody>
              {projects !== undefined ? (
                projects.map((project: Project) => (
                  <tr key={project.id + ' ' + project.name}>
                    <td>{project.id}</td>
                    {project.id === updatingProject?.id ? (
                      <>
                        <td>
                          <Form.Control
                            type='name'
                            placeholder={t('ENTER_PROJECT_NAME')}
                            value={updatingProject.name}
                            onChange={(e) =>
                              setUpdatingProject((prev) => ({
                                ...prev,
                                name: e.target.value,
                              }))
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            as='textarea'
                            rows={6}
                            placeholder={t('ENTER_PROJECT_DESCRIPTION')}
                            value={updatingProject.description}
                            onChange={(e) =>
                              setUpdatingProject((prev) => ({
                                ...prev,
                                description: e.target.value,
                              }))
                            }
                          />
                        </td>
                        <td>{t(project.isInternal ? 'YES' : 'NO')}</td>
                        <td>
                          <Form.Control
                            as='select'
                            value={updatingProject.originCountry?.id}
                            onChange={(e) => {
                              const newCountry: Country | undefined =
                                countries.find(
                                  (country) => country.id === +e.target.value
                                )
                              setUpdatingProject((prev) => ({
                                ...prev,
                                originCountry:
                                  newCountry !== undefined
                                    ? newCountry
                                    : ({} as Country),
                              }))
                            }}
                          >
                            {countries.map((country: Country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            as='select'
                            value={
                              updatingProject?.projectManager !== undefined &&
                              updatingProject?.projectManager?.id !== undefined
                                ? updatingProject.projectManager?.id
                                : ''
                            }
                            onChange={(e) => {
                              const newEmployee: Employee | undefined =
                                employees.find(
                                  (employee) => employee.id === +e.target.value
                                )
                              setUpdatingProject((prev) => ({
                                ...prev,
                                projectManager:
                                  newEmployee !== undefined
                                    ? newEmployee
                                    : ({} as Employee),
                              }))
                            }}
                          >
                            <option value=''>...</option>
                            {employees.map((employee: Employee) => (
                              <option key={employee.id} value={employee.id}>
                                {employee.firstName + ' ' + employee.lastName}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td>
                          <Button
                            className='col-11 m-2'
                            variant='success'
                            onClick={handleUpdateProjectInfo}
                          >
                            {t('SAVE_CHANGES')}
                          </Button>
                          <Button
                            className='col-11 m-2'
                            variant='danger'
                            onClick={() => setUpdatingProject({} as Project)}
                          >
                            {t('CANCEL_CHANGES')}
                          </Button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{project.name}</td>
                        <td>{project.description}</td>
                        <td>{project.isInternal ? 'Yes' : 'No'}</td>
                        <td>{project.originCountry?.name}</td>
                        <td>
                          {project.projectManager
                            ? `${project.projectManager?.lastName} ${project.projectManager?.firstName}`
                            : t('NONE')}
                        </td>
                        <td>
                          <DropdownButton title={t('ACTIONS')}>
                            <Dropdown.Item
                              onClick={() => setUpdatingProject(project)}
                            >
                              {t('UPDATE_PROJECT_INFO')}
                            </Dropdown.Item>
                            {projectsWithoutVacationAndIllness.includes(
                              project
                            ) && (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setUpdatingProject(project)
                                    setIsUpdatingInvoicingDataVisible(true)
                                  }}
                                >
                                  {t('UPDATE_INVOICING_DATA')}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <OverlayTrigger
                                  trigger='click'
                                  placement='bottom'
                                  overlay={
                                    <Popover id='popover-basic'>
                                      <Popover.Header as='h3'>
                                        {t('CONFIRMATION')}
                                      </Popover.Header>
                                      <Popover.Body>
                                        {t(
                                          'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PROJECT'
                                        )}
                                        <br />
                                        <Button
                                          variant='danger'
                                          onClick={() =>
                                            handleDeleteProject(project)
                                          }
                                        >
                                          {t('YES')}
                                        </Button>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <Button variant='danger' className='m-2'>
                                    {t('DELETE_PROJECT')}
                                  </Button>
                                </OverlayTrigger>
                              </>
                            )}
                          </DropdownButton>
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <p>{t('LOADING_THREE_DOTS')}</p>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default HandleProjectPage
