import React, { useState, useEffect } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Currency, Grade, Project } from '../../utils/LoginDTOS'

interface ChangeGradeRateModalProps {
  visible: boolean
  toggleVisible: () => void
  onSubmit: (
    newValue: number,
    gradeId: number,
    projectId: number,
    currencyId: number
  ) => void
  selectedGrade: Grade
  selectedProject: Project
  selectedCurrency: Currency
  currentValue: number
}

const ChangeGradeRateModal = (props: ChangeGradeRateModalProps) => {
  const {
    visible,
    toggleVisible,
    onSubmit,
    selectedGrade,
    selectedProject,
    selectedCurrency,
    currentValue,
  } = props

  const [newRate, setNewRate] = useState<number>(currentValue)

  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      setNewRate(currentValue)
    }
  }, [visible])

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(
      newRate,
      selectedGrade.id,
      selectedProject.id ? selectedProject.id : 0,
      selectedCurrency.id
    )
  }

  return (
    <Modal show={visible} onHide={toggleVisible}>
      <Modal.Header closeButton>
        <Modal.Title>{t('CHANGE_GRADE_RATE')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Text className='text-muted'>
            {t('INPUT_NEW_VALUE_FOR_SELECTED_GRADE_FOR_HOUR')}
          </Form.Text>
          <br />
          <Form.Text style={{ backgroundColor: 'yellow', borderRadius: '5px' }}>
            {t('BE_CAREFUL_NEW_VALUE_WILL_BE_USED_IN_INVOICES_FOR_CLIENTS')}B
          </Form.Text>
          <br />
          <Form.Text className='text-muted'>
            {t('CURRENT_VALUE_DOUBLE_DOTS')} {currentValue}{' '}
            {selectedCurrency.code} {t('FOR_LOWERCASE')} {selectedGrade.name}{' '}
            {t('ON_LOWERCASE')} {selectedProject.name} {t('FOR_HOUR_LOWERCASE')}
          </Form.Text>

          <Form.Group controlId='formEmployee' className='mt-3'>
            <Form.Label>
              {t('INPUT_NEW_RATE_IN')} {selectedCurrency.code}{' '}
              {t('PHRASE_FOR_HOUR')}
            </Form.Label>
            <Form.Control
              type='number'
              value={newRate}
              onChange={(e) => setNewRate(+e.target.value)}
              min='1'
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={toggleVisible}>
            {t('CLOSE')}
          </Button>
          <Button variant='primary' onClick={toggleVisible} type='submit'>
            {t('CHANGE')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ChangeGradeRateModal
