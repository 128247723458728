import { API_PATHS } from './ApiUtils';
import axios, { AxiosResponse } from 'axios';
import { DateHelper } from './CalendarUtils';

  async function createReportOfEmployeesOnTheProjectBetweenDates(projectId: number, dates: DateHelper[]) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTS_INVOICES.GENERATE_REPORT_OF_EMPLOYEES_ON_THE_PROJECT, 
        {projectId, dates},
        {responseType: 'blob'}
        );
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function createReportOfEmployees() {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTS_INVOICES.GENERATE_REPORT_OF_EMPLOYEES, 
        {},
        {responseType: 'blob'}
        );
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function createReportOfOneEmployee(employeeId: number, dates: DateHelper[]) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTS_INVOICES.GENERATE_REPORT_OF_ONE_EMPLOYEE, 
        {employeeId, dates},
        {responseType: 'blob'}
        );
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function generateInvoiceForOneProject(projectId: number, dates: DateHelper[]) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTS_INVOICES.GENERATE_INVOICE_FOR_ONE_CLIENT, 
        {projectId, dates},
        {responseType: 'blob'}
        );
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

export { createReportOfEmployeesOnTheProjectBetweenDates, createReportOfEmployees, createReportOfOneEmployee, generateInvoiceForOneProject };

  