import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Employee, Project } from '../../utils/LoginDTOS'

interface EnrollEmployeeToTheProjectModalProps {
  visible: boolean
  toggleVisible: () => void
  onSubmit: (
    employeeId: number,
    projectId: number,
    isEnrolling: boolean
  ) => void
  projectsWhereAmPM: Project[]
  employees: Employee[]
}

const EnrollEmployeeToTheProjectModal = (
  props: EnrollEmployeeToTheProjectModalProps
) => {
  const { visible, toggleVisible, onSubmit, projectsWhereAmPM, employees } =
    props

  const { t } = useTranslation()

  const [selectedProjectId, setSelectedProjectId] = useState<string>('')
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('')
  const [isEnrolling, setIsEnrolling] = useState<boolean>(true)

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(Number(selectedEmployeeId), Number(selectedProjectId), isEnrolling)
  }

  return (
    <Modal show={visible} onHide={toggleVisible}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('ENROLL_REMOVE_EMPLOYEE_TO_FROM_THE_PROJECT')}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Text className='text-muted'>
            {t('HERE_YOU_CAN_ENROLL_THE_EMPLOYEE_TO_THE_PROJECT')} <br />
            {t('MANAGER_CAN_ENROLL_EVERYBODY_TO_EVERY_PROJECT')}
          </Form.Text>

          <Form.Group controlId='isEnrolling' className='mt-3'>
            <Form.Text>{t('SELECT_ACTION')}</Form.Text>
            <Form.Check
              type='radio'
              checked={isEnrolling}
              onClick={() => setIsEnrolling(true)}
              label={t('ENROLL')}
            />
            <Form.Check
              type='radio'
              checked={!isEnrolling}
              onClick={() => setIsEnrolling(false)}
              label={t('REMOVE')}
            />
          </Form.Group>

          <Form.Group controlId='formEmployee' className='mt-3'>
            <Form.Label>{t('SELECT_EMPLOYEE')}</Form.Label>
            <Form.Control
              as='select'
              value={selectedEmployeeId}
              onChange={(e) => setSelectedEmployeeId(e.target.value)}
              required
            >
              <option value=''>...</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {selectedEmployeeId && (
            <Form.Group controlId='formProject' className='mt-3'>
              <Form.Label>{t('SELECT_PROJECT')}</Form.Label>
              <Form.Control
                as='select'
                value={selectedProjectId}
                onChange={(e) => setSelectedProjectId(e.target.value)}
                required
              >
                <option value=''>...</option>
                {projectsWhereAmPM.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={toggleVisible}>
            {t('CLOSE')}
          </Button>
          <Button
            variant='primary'
            onClick={toggleVisible}
            type='submit'
            disabled={!(selectedEmployeeId && selectedProjectId)}
          >
            {t('ENROLL')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EnrollEmployeeToTheProjectModal
