import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { API_PATHS } from '../../utils/ApiUtils'
import { Country } from '../../utils/LoginDTOS'

interface CountrieContextProps {
  countries: Country[]
}

const CountriesContext = createContext<CountrieContextProps>({ countries: [] })

export const useCountriesContext = () => {
  return useContext(CountriesContext)
}

interface CountriesProviderProps {
  children: React.ReactNode
  setIsLoadingFalse: () => void
}

export const CountriesProvider: React.FC<CountriesProviderProps> = ({
  setIsLoadingFalse,
  children,
}) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    async function fetchCountries() {
      const response = await axios.get(API_PATHS.COUNTRY.GET_ALL)
      setCountries(
        response.data.map((country: Country) => ({
          ...country,
        }))
      )
      setIsLoadingFalse()
    }

    fetchCountries()
  }, [])

  return (
    <CountriesContext.Provider value={{ countries }}>
      {children}
    </CountriesContext.Provider>
  )
}
