import { API_PATHS } from './ApiUtils';
import axios, { AxiosResponse } from 'axios';

async function grantManagerRole(employeeId: number) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.ROLES.POST_GRANT_MANAGER_ROLE, { employeeId });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function grantProjectManagerRole(employeeId: number, projectId: number) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.ROLES.POST_GRANT_PROJECT_MANAGER_ROLE, { employeeId, projectId });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function enrollEmployeeToProject(employeeId: number, projectId: number) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.PROJECT_EMPLOYEE.POST_ENROLL_EMPLOYEE_ON_PROJECT, { employeeId, projectId });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

    async function removeEmployeeToProject(employeeId: number, projectId: number) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.PROJECT_EMPLOYEE.POST_REMOVE_EMPLOYEE_ON_PROJECT, { employeeId, projectId });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

export { grantManagerRole, grantProjectManagerRole, enrollEmployeeToProject, removeEmployeeToProject };

  