import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Employee, Project } from '../../utils/LoginDTOS'

interface GrantProjectManagerRoleModalProps {
  visible: boolean
  toggleVisible: () => void
  onSubmit: (employeeId: number, projectId: number) => void
  projectsWhereAmPM: Project[]
  employees: Employee[]
}

const GrantProjectManagerRoleModal = (
  props: GrantProjectManagerRoleModalProps
) => {
  const { visible, toggleVisible, onSubmit, projectsWhereAmPM, employees } =
    props

  const { t } = useTranslation()

  const [selectedProjectId, setSelectedProjectId] = useState<string>('')
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('')

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(Number(selectedEmployeeId), Number(selectedProjectId))
  }

  return (
    <Modal show={visible} onHide={toggleVisible}>
      <Modal.Header closeButton>
        <Modal.Title>{t('GRANT_PROJECT_MANAGER_ROLE_TO_EMPLOYEE')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Text className='text-muted'>
            {t('ASSIGN_PROJECT_MANAGER_ROLE_FOR_SPECIFIC_PROJECTS')}
          </Form.Text>
          <Form.Group controlId='formProject' className='mt-3'>
            <Form.Label>{t('SELECT_PROJECT')}</Form.Label>
            <Form.Control
              as='select'
              value={selectedProjectId}
              onChange={(e) => setSelectedProjectId(e.target.value)}
              required
            >
              <option value=''>...</option>
              {projectsWhereAmPM.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formEmployee' className='mt-3'>
            <Form.Label>{t('SELECT_EMPLOYEE')}</Form.Label>
            <Form.Control
              as='select'
              value={selectedEmployeeId}
              onChange={(e) => setSelectedEmployeeId(e.target.value)}
              required
            >
              <option value=''>...</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={toggleVisible}>
            {t('CLOSE')}
          </Button>
          <Button variant='primary' onClick={toggleVisible} type='submit'>
            {t('GRANT')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default GrantProjectManagerRoleModal
