const BE_BASE_URL = process.env.REACT_APP_BE_BASE_URL;

export const API_PATHS = {
    AUTHENTICATION: {
        LOGIN: `${BE_BASE_URL}/authentication/login`,
        CHECK_LOGIN: `${BE_BASE_URL}/authentication/checkLogin`,
        LOGOUT: `${BE_BASE_URL}/authentication/logout`,
        DELETE_USER: `${BE_BASE_URL}/authentication/users`,
    },
    PROJECT: {
        GET_ALL: `${BE_BASE_URL}/project/projects`,
        GET_USER_PROJECTS: `${BE_BASE_URL}/project/getEmployeeProjects`,
        POST_UPDATE_PROJECT_DATA: `${BE_BASE_URL}/project/updateProjectData`,
        POST_CREATE_PROJECT: `${BE_BASE_URL}/project/createProject`,
        POST_UPDATE_INVOICING_DATA: `${BE_BASE_URL}/project/updateInvoicingData`,
        GET_OWN_COMPANY_DATA: `${BE_BASE_URL}/project/getOwnCompanyData`,
        POST_UPDATE_OWN_COMPANY_DATA: `${BE_BASE_URL}/project/updateOwnCompanyData`,
        POST_DELETE_PROJECT: `${BE_BASE_URL}/project/deleteProject`,
    },
    REPORTING: {
        POST_REPORT_TIME: `${BE_BASE_URL}/reporting/reportTime`,
        POST_REPORT_ILLNESS: `${BE_BASE_URL}/reporting/reportIllness`,
        POST_REPORT_VACATION: `${BE_BASE_URL}/reporting/reportVacation`,
        GET_REPORTED_TIME: `${BE_BASE_URL}/reporting/getReportedTime`,
        DELETE_REPORTED_TIME_ROW: `${BE_BASE_URL}/reporting/deleteReportedTimeRow`,
        UPDATE_REPORTED_TIME: `${BE_BASE_URL}/reporting/updateReportedTime`,
        GET_ALL_ILLNESS: `${BE_BASE_URL}/reporting/getAllIllness`,
        GET_ALL_VACATION: `${BE_BASE_URL}/reporting/getAllVacation`,
    },
    PROJECT_EMPLOYEE: {
        POST_ENROLL_EMPLOYEE_ON_PROJECT: `${BE_BASE_URL}/projectEmployee/enrollEmployeeOnProject`,
        POST_REMOVE_EMPLOYEE_ON_PROJECT: `${BE_BASE_URL}/projectEmployee/removeEmployeeOnProject`,
    },
    BANK_HOLIDAY: {
        POST_CREATE_NEW_BANK_HOLIDAY: `${BE_BASE_URL}/bankHoliday/createNew`,
        GET_ALL: `${BE_BASE_URL}/bankHoliday/getAll`,
    },
    EMPLOYEE: {
        CREATE_NEW: `${BE_BASE_URL}/employee/createNew`,
        GET_ALL: `${BE_BASE_URL}/employee/getAll`,
        PUT_UPDATE_EMPLOYEE: `${BE_BASE_URL}/employee/update`,
        POST_UPDATE_PASSWORD: `${BE_BASE_URL}/employee/updatePassword`,
        POST_UPDATE_IMAGE: `${BE_BASE_URL}/employee/updateImage`,
        POST_UPDATE_EMPLOYEE_GRADE: `${BE_BASE_URL}/employee/updateGrade`,
    },
    ROLES: {
        POST_GRANT_MANAGER_ROLE: `${BE_BASE_URL}/roles/grantManagerRole`,
        POST_GRANT_PROJECT_MANAGER_ROLE: `${BE_BASE_URL}/roles/grantProjectManagerRole`
    },
    COUNTRY: {
        GET_ALL: `${BE_BASE_URL}/country/getAll`
    },
    GRADE: {
        GET_ALL: `${BE_BASE_URL}/grade/getAll`
    },
    CURRENCY: {
        GET_ALL: `${BE_BASE_URL}/currency/getAll`
    },
    PROJECT_RATES: {
        GET_ALL: `${BE_BASE_URL}/projectRates/getAll`,
        UPDATE_BY_GRADE_ID_AND_PROJECT_ID: `${BE_BASE_URL}/projectRates/updateByGradeIdAndProjectId`
    },
    REPORTS_INVOICES: {
        GENERATE_REPORT_OF_EMPLOYEES_ON_THE_PROJECT: `${BE_BASE_URL}/reportInvoices/createReportOfEmployeesOnTheProjectBetweenDates`,
        GENERATE_REPORT_OF_EMPLOYEES: `${BE_BASE_URL}/reportInvoices/createReportOfEmployees`,
        GENERATE_REPORT_OF_ONE_EMPLOYEE: `${BE_BASE_URL}/reportInvoices/createReportOfOneEmployee`,
        GENERATE_INVOICE_FOR_ONE_CLIENT: `${BE_BASE_URL}/reportInvoices/createInvoiceForOneProject`,
    }
};
