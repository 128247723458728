import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { API_PATHS } from '../../utils/ApiUtils'
import { Country, Currency } from '../../utils/LoginDTOS'

interface CurrenciesContextProps {
  currencies: Currency[]
}

const CurrenciesContext = createContext<CurrenciesContextProps>({
  currencies: [],
})

export const useCurrenciesContext = () => {
  return useContext(CurrenciesContext)
}

interface CurrenciesProviderProps {
  children: React.ReactNode
}

export const CurrenciesProvider: React.FC<CurrenciesProviderProps> = ({
  children,
}) => {
  const [currencies, setCurrencies] = useState<Currency[]>([])

  useEffect(() => {
    async function fetchCurrencies() {
      const response = await axios.get(API_PATHS.CURRENCY.GET_ALL)
      setCurrencies(
        response.data.map((currency: Currency) => ({
          ...currency,
        }))
      )
    }

    fetchCurrencies()
  }, [])

  return (
    <CurrenciesContext.Provider value={{ currencies: currencies }}>
      {children}
    </CurrenciesContext.Provider>
  )
}
