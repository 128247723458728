import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'
import { EmployeeWithRoles, Role } from '../utils/LoginDTOS'
import { useEmployeesContext } from './context/getEmployees.context'
import { useTranslation } from 'react-i18next'

const Navigation = () => {
  const { isLoggedIn, logoutContext, currentUser } = useIsLoggedInContext()
  const { employees } = useEmployeesContext()
  const { i18n } = useTranslation()

  const { t } = useTranslation()

  const handleLogout = async () => {
    logoutContext()
  }

  const currentEmployeeWithRoles = useMemo<EmployeeWithRoles>(() => {
    return employees.filter((empl) => empl?.id === currentUser?.id)[0]
  }, [employees, currentUser])

  const haveRole = (roles: Role[], roleName: string): boolean => {
    return roles?.filter((role: Role) => role.name === roleName).length > 0
  }

  const canAccessAdministrationPage = useMemo<boolean>(() => {
    return (
      haveRole(currentEmployeeWithRoles?.roles, 'manager') ||
      haveRole(currentEmployeeWithRoles?.roles, 'project_manager')
    )
  }, [currentEmployeeWithRoles])

  const canAccessInvoicesPage = useMemo<boolean>(() => {
    return haveRole(currentEmployeeWithRoles?.roles, 'accountant')
  }, [currentEmployeeWithRoles])

  const canAccessReportsPage = useMemo<boolean>(() => {
    return (
      haveRole(currentEmployeeWithRoles?.roles, 'manager') ||
      haveRole(currentEmployeeWithRoles?.roles, 'project_manager')
    )
  }, [currentEmployeeWithRoles])

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <Navbar
      bg='dark'
      data-bs-theme='dark'
      expand='lg'
      fixed='top'
      className='bg-body-tertiary'
    >
      <Container>
        <Navbar.Brand as={Link} to='/'>
          Reportify
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            {isLoggedIn && (
              <>
                <Nav.Link as={Link} to='/start'>
                  Start
                </Nav.Link>
                <Nav.Link as={Link} to='/report'>
                  {t('REPORT_TIME')}
                </Nav.Link>
                {canAccessAdministrationPage && (
                  <Nav.Link as={Link} to='/administration'>
                    {t('ADMINISTRATION')}
                  </Nav.Link>
                )}
                {canAccessInvoicesPage && (
                  <Nav.Link as={Link} to='/finance/invoices'>
                    {t('INVOICES')}
                  </Nav.Link>
                )}
                {canAccessReportsPage && (
                  <Nav.Link as={Link} to='/finance/reports'>
                    {t('REPORTS')}
                  </Nav.Link>
                )}
              </>
            )}
          </Nav>
          <Nav>
            <NavDropdown
              title={<FontAwesomeIcon icon={faGlobe} />}
              id='language-nav-dropdown'
            >
              <NavDropdown.Item onClick={() => changeLanguage('en')}>
                English
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage('de')}>
                German
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage('pl')}>
                Polish
              </NavDropdown.Item>
            </NavDropdown>
            {isLoggedIn ? (
              <NavDropdown
                title={<FontAwesomeIcon icon={faUser} />}
                id='user-nav-dropdown'
              >
                <NavDropdown.Item as={Link} to='/profile'>
                  {t('PROFILE')}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <button className='dropdown-item' onClick={handleLogout}>
                  {t('LOGOUT')}
                </button>
              </NavDropdown>
            ) : (
              <Nav.Link as={Link} to='/login'>
                {t('LOGIN')}
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
