import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { API_PATHS } from '../../utils/ApiUtils'
import { Project } from '../../utils/LoginDTOS'
import { useIsLoggedInContext } from './IsLoggedInContext.context'

interface UserProjectsContextProps {
  userProjects: Project[]
}

const UserProjectsContext = createContext<UserProjectsContextProps>({
  userProjects: [],
})

export const useUserProjectsContext = () => {
  return useContext(UserProjectsContext)
}

interface UserProjectsProviderProps {
  children: React.ReactNode
}

export const UserProjectsProvider: React.FC<UserProjectsProviderProps> = ({
  children,
}) => {
  const [userProjects, setUserProjects] = useState<Project[]>([])
  const { currentUser } = useIsLoggedInContext()

  useEffect(() => {
    async function fetchProjects() {
      if (currentUser) {
        const response = await axios.get(API_PATHS.PROJECT.GET_USER_PROJECTS, {
          params: { employeeId: currentUser.id },
        })
        setUserProjects(response.data)
      }
    }
    fetchProjects()
  }, [currentUser])

  return (
    <UserProjectsContext.Provider value={{ userProjects }}>
      {children}
    </UserProjectsContext.Provider>
  )
}
