import { Project } from './LoginDTOS';
import { API_PATHS } from './ApiUtils';
import axios from 'axios';

export interface UpdateProjectDataDTO {
    id: number,
    newName: string,
    newDescription: string,
    newOriginCountryId: number
    newProjectManagerId: number
}

async function updateProjectData(project: Project) {
  try {
      const updateDTO: UpdateProjectDataDTO = {
        id: project.id !== undefined ? project.id : 0,
        newName: project.name,
        newDescription: project.description,
        newOriginCountryId: project.originCountry.id,
        newProjectManagerId: project.projectManager?.id !== undefined ? project.projectManager?.id : 0
      }
      const response = await axios.post(API_PATHS.PROJECT.POST_UPDATE_PROJECT_DATA, updateDTO)
        return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error;
  }
}

export interface CreateProjectDTO {
  name: string, 
  description: string, 
  originCountryId: number, 
  projectManagerId: number,
  legalName: string,
  address: string,
  phoneNumber: string
}

async function createProject(project: Project) {
    try {
        const response = await axios.post(API_PATHS.PROJECT.POST_CREATE_PROJECT, {
          name: project.name, 
          description: project.description, 
          originCountryId: project.originCountry.id, 
          projectManagerId: project.projectManager === undefined ? 0 : project.projectManager.id,
          legalName: project.legalName,
          address: project.address,
          phoneNumber: project.phoneNumber} as CreateProjectDTO)
          return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  export interface UpdateProjectInvoicingDataDTO {
    projectId: number, 
    legalName: string,
    address: string,
    phoneNumber: string
  }

  async function updateInvoicingDataProject(project: Project) {
    try {
        const response = await axios.post(API_PATHS.PROJECT.POST_UPDATE_INVOICING_DATA, {
          projectId: project.id,
          legalName: project.legalName,
          address: project.address,
          phoneNumber: project.phoneNumber} as UpdateProjectInvoicingDataDTO)
          return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  export interface OwnCompanyData {
    name: string,
    accountNumber: string,
    address: string,
    telephoneNumber: string
  }
  
  async function getOwnCompanyData(): Promise<OwnCompanyData> {
    try {
        const response = await axios.get(API_PATHS.PROJECT.GET_OWN_COMPANY_DATA)
          return response.data as OwnCompanyData;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function updateOwnCompanyData(ownCompanyData: Project) {
    try {
        const response = await axios.post(API_PATHS.PROJECT.POST_UPDATE_OWN_COMPANY_DATA, {
          name: ownCompanyData.legalName,
          accountNumber: ownCompanyData.bankAccount,
          address: ownCompanyData.address,
          telephoneNumber: ownCompanyData.phoneNumber})
          return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function updateEmployeeGrade(employeeId: number, gradeId: number) {
    try {
        const response = await axios.post(API_PATHS.EMPLOYEE.POST_UPDATE_EMPLOYEE_GRADE, {
          employeeId: employeeId,
          gradeId: gradeId})
          return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function deleteProject(formData: FormData) {
    try {
        const response = await axios.post(API_PATHS.PROJECT.POST_DELETE_PROJECT, formData)
          return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

export { updateProjectData, createProject, updateInvoicingDataProject, getOwnCompanyData, updateOwnCompanyData, updateEmployeeGrade, deleteProject } 