type CalendarPiece = Date | null

export type CalendarValue = CalendarPiece | [CalendarPiece, CalendarPiece]

export type DateHelper = {
    dateName: string;
    date: Date;
    isWeekend: boolean;
};

function isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
}

function createDateHelper(dateName: string, date: Date): DateHelper {
    return {
        dateName,
        date,
        isWeekend: isWeekend(date)
    };
}

function formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

function isCalendarPieceArray(value: CalendarValue): value is [CalendarPiece, CalendarPiece] {
    return Array.isArray(value);
}

function isCalendarNotPieceArray(value: CalendarValue): value is Date {
    return !Array.isArray(value);
}

function createDateHelperRange(dates: CalendarValue): DateHelper[] {
    const dateHelpers: DateHelper[] = [];
    if (isCalendarPieceArray(dates)) {
        const [start, end] = dates;

        const startDate = start as Date;
        const endDate = end as Date;

        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {    
            const dateName: string = getWeekdayName(currentDate);
            const isCurrentDateWeekend: boolean = isWeekend(currentDate);

            dateHelpers.push({
                dateName,
                date: new Date(currentDate),
                isWeekend: isCurrentDateWeekend
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    return dateHelpers;
}

function getWeekdayName(date: Date): string {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return weekdays[date.getDay()];
}

export { isWeekend, createDateHelper, createDateHelperRange, getWeekdayName, formatDate, isCalendarPieceArray, isCalendarNotPieceArray };