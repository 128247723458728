import axios, { AxiosResponse } from "axios";
import { API_PATHS } from "./ApiUtils";
import { DateHelper } from "./CalendarUtils";
import { ReportTimeByEmployeeDTO } from "./TimeReportDTOS";

async function reportIllness(employeeId: number, dates: DateHelper[]) {
  try {
    const response: AxiosResponse = await axios.post(API_PATHS.REPORTING.POST_REPORT_ILLNESS, {employeeId, dates});
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error;
  }
}

async function reportVacation(employeeId: number, dates: DateHelper[]) {
  try {
    const response: AxiosResponse = await axios.post(API_PATHS.REPORTING.POST_REPORT_VACATION, {employeeId, dates});
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error;
  }
}

  async function reportTime(reportTimeDTO: ReportTimeByEmployeeDTO) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTING.POST_REPORT_TIME, reportTimeDTO);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  interface GetReportTimeByEmployeeIdDTO {
    employeeId: number,
    dateFrom: Date,
    dateTo: Date,
  }

  async function getReportTimeByEmployeeId(employeeId: number, dateFrom: Date, dateTo: Date) {
    try {
      const body: GetReportTimeByEmployeeIdDTO = {employeeId, dateFrom, dateTo}
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTING.GET_REPORTED_TIME, body);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function deleteReportingTimeRow(projectId: number, employeeId: number, date: Date) {
    try {
        const formattedDate = new Date(date).toISOString().split('T')[0];
      const response: AxiosResponse = await axios.delete(API_PATHS.REPORTING.DELETE_REPORTED_TIME_ROW, {
        params: {
            projectId: projectId,
            employeeId: employeeId,
            dateOfReporting: formattedDate
        }
    });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  
export interface UpdateReportTimeDTO {
    employeeId: number,
    projectId: number,
    date: Date,
    durationInHours: number
}

  async function updateTimeReport(updateReportTime: UpdateReportTimeDTO) {
    try {
        const body = {
            employeeId: updateReportTime.employeeId,
            projectId: updateReportTime.projectId,
            date: updateReportTime.date,
            durationInHours: updateReportTime.durationInHours
        };
      const response: AxiosResponse = await axios.post(API_PATHS.REPORTING.UPDATE_REPORTED_TIME, body);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function getAllIllness(userId: number) {
    try {
      const response: AxiosResponse = await axios.get(API_PATHS.REPORTING.GET_ALL_ILLNESS, { params: {userId} });
      return response.data as Date[];
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }
  
  async function getAllVacation(userId: number) {
    try {
      const response: AxiosResponse = await axios.get(API_PATHS.REPORTING.GET_ALL_VACATION, { params: {userId} });
      return response.data as Date[];
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

export {reportTime, getReportTimeByEmployeeId, deleteReportingTimeRow, updateTimeReport, reportIllness, reportVacation, getAllIllness, getAllVacation}