import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Employee, EmployeeWithRoles } from '../utils/LoginDTOS'

export function isEmployee(obj: any): obj is Employee {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.id === 'number' &&
    typeof obj.login === 'string'
  )
}

export function isEmployeeWithRoles(obj: any): obj is EmployeeWithRoles {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.id === 'number' &&
    typeof obj.login === 'string' &&
    typeof obj.roles !== undefined
  )
}

const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [currentUser, setCurrentUser] = useState<EmployeeWithRoles | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const userIsLoggedIn: boolean =
      localStorage.getItem('isLoggedIn') === 'true' &&
      localStorage.getItem('jwtToken') !== undefined &&
      localStorage.getItem('currentUser') !== undefined
    setIsLoggedIn(userIsLoggedIn)

    const storedUser = localStorage.getItem('currentUser')
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser)
      if (isEmployeeWithRoles(parsedUser)) {
        let currentUser: EmployeeWithRoles = parsedUser
        setCurrentUser(currentUser)
      }
    }
  }, [])

  const loginContext = (response: any) => {
    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem('jwtToken', response.token)
    localStorage.setItem('currentUser', JSON.stringify(response.user))
    setIsLoggedIn(true)
    setCurrentUser(response.user)
    navigate('/start')
  }

  const logoutContext = () => {
    localStorage.setItem('isLoggedIn', 'false')
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('currentUser')
    setIsLoggedIn(false)
    setCurrentUser(null)
    navigate('/')
  }

  const refreshCurrentUserInCookies = (employee: Employee) => {
    localStorage.setItem('currentUser', JSON.stringify(employee))
    setCurrentUser((prev) => ({ ...prev, ...employee } as EmployeeWithRoles))
  }

  return {
    isLoggedIn,
    loginContext,
    logoutContext,
    currentUser,
    refreshCurrentUserInCookies,
  }
}

export default useIsLoggedIn
