import React, { useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { createNewBankHoliday } from '../utils/BankHolidayUtils'
import { ToastTypes } from '../utils/ToastUtils'
import CreateBankHolidayModal from './modals/CreateBankHolidayModal'
import EnrollEmployeeToTheProjectModal from './modals/EnrollEmployeeToTheProjectModal'
import GrantManagerRoleModal from './modals/GrantManagerRoleModal'
import GrantProjectManagerRoleModal from './modals/GrantProjectManagerRoleModal'
import SelectProjectToChangeRatesModal from './modals/SelectProjectToChangeRatesModal'
import { useToast } from './context/ToastContext.context'
import { useEmployeesContext } from './context/getEmployees.context'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'
import {
  enrollEmployeeToProject,
  grantManagerRole,
  grantProjectManagerRole,
  removeEmployeeToProject,
} from '../utils/RoleUtils'
import { EmployeeWithRoles, Project, Role } from '../utils/LoginDTOS'
import { useProjectsContext } from './context/getProjects.context'
import { useTranslation } from 'react-i18next'
import UpdateProjectInvoicingDataModal from './modals/UpdateProjectInvoicingDataModal'
import {
  getOwnCompanyData,
  OwnCompanyData,
  updateEmployeeGrade,
  updateOwnCompanyData,
} from '../utils/ProjectUtils'
import UpdateEmployeeGradeModal from './modals/UpdateEmployeeGradeModal'
import { useGradesContext } from './context/getGrade.context'

const AdministrationPage = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const { addToast } = useToast()
  const { currentUser } = useIsLoggedInContext()
  const { employees, fetchEmployees } = useEmployeesContext()
  const { projects, fetchProjects } = useProjectsContext()
  const { grades } = useGradesContext()

  const notMeEmployees = useMemo(() => {
    return employees.filter((el) => el.id !== currentUser?.id)
  }, [employees, currentUser])

  const notManagerEmployees = useMemo(() => {
    return notMeEmployees.filter(
      (employee) => !employee.roles.map((role) => role.name).includes('manager')
    )
  }, [notMeEmployees])

  const currentEmployeeWithRoles = useMemo<EmployeeWithRoles>(() => {
    return employees.filter((empl) => empl?.id === currentUser?.id)[0]
  }, [employees, currentUser])

  const projectsWithoutVacationAndIllness = useMemo(() => {
    return projects.filter(
      (project: Project) =>
        project.name !== 'Vacations' && project.name !== 'Illness'
    )
  }, [projects])

  const projectsWhereAmPMOrAllProjectsIfManager = useMemo<Project[]>(() => {
    if (
      currentEmployeeWithRoles === undefined ||
      currentEmployeeWithRoles.roles === undefined
    ) {
      return []
    }
    if (
      currentEmployeeWithRoles.roles.filter(
        (role: Role) => role.name === 'manager'
      ).length > 0
    ) {
      return projectsWithoutVacationAndIllness
    } else {
      return projectsWithoutVacationAndIllness.filter(
        (project: Project) => project?.projectManager?.id === currentUser?.id
      )
    }
  }, [projectsWithoutVacationAndIllness, currentUser, currentEmployeeWithRoles])

  const [enrollEmployeeToProjectVisible, setEnrollEmployeeToProjectVisible] =
    useState<boolean>(false)
  const [createBankHolidayVisible, setCreateBankHolidayVisible] =
    useState<boolean>(false)
  const [grantManagerRoleVisible, setGrantManagerRoleVisible] =
    useState<boolean>(false)
  const [grantProjectManagerRoleVisible, setGrantProjectManagerRoleVisible] =
    useState<boolean>(false)
  const [selectProjectToChangeRates, setSelectProjectToChangeRates] =
    useState<boolean>(false)
  const [isUpdatingInvoicingDataVisible, setIsUpdatingInvoicingDataVisible] =
    useState<boolean>(false)
  const [isChangeGradeForEmployeeVisible, setIsChangeGradeForEmployeeVisible] =
    useState<boolean>(false)

  const [ownCompanyData, setOwnCompanyData] = useState<Project>({} as Project)

  const handleEnrollRemoveEmployeeToProject = async (
    employeeId: number,
    projectId: number,
    isEnrolling: boolean
  ) => {
    if (isEnrolling) {
      const response = await enrollEmployeeToProject(employeeId, projectId)
      if (typeof response === 'string') {
        addToast(t('FAILURE'), t(response), ToastTypes.ERROR)
      } else {
        addToast(
          t('SUCCESS'),
          t('EMPLOYEE_HAS_BEEN_ENROLLED_TO_THE_PROJECT'),
          ToastTypes.DEFAULT
        )
        fetchEmployees()
      }
    } else {
      const response = await removeEmployeeToProject(employeeId, projectId)
      if (typeof response === 'string') {
        addToast(t('FAILURE'), t(response), ToastTypes.ERROR)
      } else {
        addToast(
          t('SUCCESS'),
          t('EMPLOYEE_HAS_BEEN_REMOVED_FROM_THE_PROJECT'),
          ToastTypes.DEFAULT
        )
        fetchEmployees()
      }
    }
  }

  const handleCreateBankHoliday = async (name: string, date: Date) => {
    const response = await createNewBankHoliday(name, date)
    if (typeof response !== 'string') {
      addToast(
        t('SUCCESS'),
        t('BANK_HOLIDAY_HAS_BEEN_CREATED'),
        ToastTypes.DEFAULT
      )
    } else {
      addToast(t('FAILURE'), response, ToastTypes.ERROR)
    }
  }

  const handleGrantManagerRole = async (employeeId: number) => {
    const response = await grantManagerRole(employeeId)
    if (typeof response === 'string') {
      addToast(
        t('SUCCESS'),
        t('MANAGER_ROLE_HAS_BEEN_GRANTED'),
        ToastTypes.DEFAULT
      )
      fetchEmployees()
    } else {
      addToast(
        t('FAILURE'),
        t('MANAGER_ROLE_HAS_NOT_BEEN_GRANTED'),
        ToastTypes.ERROR
      )
    }
  }

  const handleGrantProjectManagerRole = async (
    employeeId: number,
    projectId: number
  ) => {
    const response = await grantProjectManagerRole(employeeId, projectId)
    if (typeof response === 'string') {
      addToast(
        t('SUCCESS'),
        t('PROJECT_MANAGER_ROLE_HAS_BEEN_GRANTED'),
        ToastTypes.DEFAULT
      )
      fetchEmployees()
    } else {
      addToast(
        t('FAILURE'),
        t('PROJECT_MANAGER_ROLE_HAS_NOT_BEEN_GRANTED'),
        ToastTypes.ERROR
      )
    }
  }

  const handleChangeRatesForProject = async (projectId: number) => {
    navigate('/changeGradesRatesOnProject', { state: { projectId: projectId } })
  }

  const handleUpdateIncoicingDataProject = async (project: Project) => {
    const response = await updateOwnCompanyData(project)
    if (typeof response !== 'string') {
      addToast(
        t('SUCCESS'),
        t('PROJECT_INVOICING_DATA_HAS_BEEN_UPDATED'),
        ToastTypes.DEFAULT
      )
    } else {
      addToast(
        t('FAILURE'),
        t('PROJECT_INVOICING_DATA_HAS_NOT_BEEN_UPDATED'),
        ToastTypes.ERROR
      )
    }
    fetchProjects()
    setOwnCompanyData({} as Project)
  }

  const handleChangeGradeForEmployee = async (
    employeeId: number,
    gradeId: number
  ) => {
    const response = await updateEmployeeGrade(employeeId, gradeId)
    if (typeof response !== 'string') {
      addToast(t('SUCCESS'), t('GRADE_HAS_BEEN_UPDATED'), ToastTypes.DEFAULT)
    } else {
      addToast(t('FAILURE'), t('GRADE_HAS_NOT_BEEN_UPDATED'), ToastTypes.ERROR)
    }
    fetchEmployees()
  }

  const fetchOwnCompanyData = async () => {
    const response: OwnCompanyData = await getOwnCompanyData()
    setOwnCompanyData({
      legalName: response.name,
      address: response.address,
      phoneNumber: response.telephoneNumber,
      bankAccount: response.accountNumber,
    } as Project)
  }

  const haveRole = (roles: Role[], roleName: string): boolean => {
    return roles?.filter((role: Role) => role.name === roleName).length > 0
  }

  const canAccessManager = useMemo<boolean>(() => {
    return haveRole(currentEmployeeWithRoles?.roles, 'manager')
  }, [currentEmployeeWithRoles])

  const canAccessProjectManager = useMemo<boolean>(() => {
    return haveRole(currentEmployeeWithRoles?.roles, 'project_manager')
  }, [currentEmployeeWithRoles])

  return (
    <>
      <EnrollEmployeeToTheProjectModal
        visible={enrollEmployeeToProjectVisible}
        toggleVisible={() => setEnrollEmployeeToProjectVisible((prev) => !prev)}
        onSubmit={handleEnrollRemoveEmployeeToProject}
        projectsWhereAmPM={projectsWhereAmPMOrAllProjectsIfManager}
        employees={employees}
      />
      <CreateBankHolidayModal
        visible={createBankHolidayVisible}
        toggleVisible={() => setCreateBankHolidayVisible((prev) => !prev)}
        onSubmit={handleCreateBankHoliday}
      />
      <GrantManagerRoleModal
        visible={grantManagerRoleVisible}
        toggleVisible={() => setGrantManagerRoleVisible((prev) => !prev)}
        onSubmit={handleGrantManagerRole}
        employees={notManagerEmployees}
      />
      <GrantProjectManagerRoleModal
        visible={grantProjectManagerRoleVisible}
        toggleVisible={() => setGrantProjectManagerRoleVisible((prev) => !prev)}
        onSubmit={handleGrantProjectManagerRole}
        projectsWhereAmPM={projectsWhereAmPMOrAllProjectsIfManager}
        employees={employees}
      />
      <SelectProjectToChangeRatesModal
        visible={selectProjectToChangeRates}
        toggleVisible={() => setSelectProjectToChangeRates((prev) => !prev)}
        onSubmit={handleChangeRatesForProject}
        projectsWhereAmPM={projectsWhereAmPMOrAllProjectsIfManager}
      />
      <UpdateProjectInvoicingDataModal
        visible={isUpdatingInvoicingDataVisible}
        updatingProject={ownCompanyData}
        onCancel={() => setIsUpdatingInvoicingDataVisible((prev) => !prev)}
        onSubmit={handleUpdateIncoicingDataProject}
        showBankAccount
      />
      <UpdateEmployeeGradeModal
        visible={isChangeGradeForEmployeeVisible}
        toggleVisible={() =>
          setIsChangeGradeForEmployeeVisible((prev) => !prev)
        }
        onSubmit={handleChangeGradeForEmployee}
        employees={employees}
        grades={grades}
      />
      <Container className='page'>
        <Row>
          {currentEmployeeWithRoles === undefined ||
          currentEmployeeWithRoles.roles === undefined ? (
            <p>{t('LOADING_THREE_DOTS')}</p>
          ) : (
            <>
              {canAccessManager && (
                <Col md={6}>
                  <Card className='mb-2'>
                    <Card.Header>
                      <h3>{t('MANAGER')}</h3>
                    </Card.Header>
                    <Card.Body>
                      <Container fluid>
                        <Row>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>{t('CREATE_NEW_USER')}</Card.Header>
                              <Card.Body>
                                {t(
                                  'REGISTER_A_NEW_USER_IN_THE_SYSTEM_TO_GRANT_ACCESS_TO_AN_EMPLOYEE'
                                )}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() => navigate('/createNewUser')}
                                    >
                                      {t('CREATE_USER')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>{t('HANDLE_PROJECTS')}</Card.Header>
                              <Card.Body>
                                {t('CREATE_DELETE_UPDATE_PROJECT_INFORMATION')}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() =>
                                        navigate('/handleProjects')
                                      }
                                    >
                                      {t('HANDLE_PROJECTS')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row className='mb-2'>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t('GRANT_MANAGER_ROLE')}
                              </Card.Header>
                              <Card.Body>
                                {t(
                                  'ASSIGN_MANAGER_ROLE_TO_DESIGNED_INDIVIDUALS'
                                )}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() =>
                                        setGrantManagerRoleVisible(true)
                                      }
                                    >
                                      {t('ASSIGN_MANAGER_ROLE')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t('CREATE_BANK_HOLIDAY')}
                              </Card.Header>
                              <Card.Body>
                                {t(
                                  'ESTABLISH_BANK_HOLIDAYY_WITHIN_THE_SYSTEM_TO_MARK_NON_WORKING_DAYS'
                                )}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() =>
                                        setCreateBankHolidayVisible(true)
                                      }
                                    >
                                      {t('CREATE_BANK_HOLIDAY')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row className='mb-2'>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t('GRANT_PROJECT_MANAGER_ROLE_TO_PROJECTS')}
                              </Card.Header>
                              <Card.Body>
                                {t(
                                  'ASSIGN_THE_PROJECT_MANAGER_ROLE_FOR_SPECIFIC_PROJECTS'
                                )}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() =>
                                        setGrantProjectManagerRoleVisible(true)
                                      }
                                    >
                                      {t('GRANT_ROLE')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t('UPDATE_INVOICE_DATA_OF_COMPANY')}
                              </Card.Header>
                              <Card.Body>
                                {t('UPDATE_INVOICE_DATA_OF_COMPANY_DETAILS')}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() => {
                                        fetchOwnCompanyData()
                                        setIsUpdatingInvoicingDataVisible(true)
                                      }}
                                    >
                                      {t('UPDATE_INVOICING_DATA')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t('UPDATE_GRADE_FOR_EMPLOYEE')}
                              </Card.Header>
                              <Card.Body>
                                {t('UPDATE_GRADE_FOR_EMPLOYEE_DETAILS')}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='success'
                                      onClick={() => {
                                        setIsChangeGradeForEmployeeVisible(true)
                                      }}
                                    >
                                      {t('UPDATE_GRADE_FOR_EMPLOYEE')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                </Col>
              )}
              {canAccessProjectManager && (
                <Col md={6}>
                  <Card>
                    <Card.Header>
                      <h3>{t('PROJECT_MANAGER')}</h3>
                    </Card.Header>
                    <Card.Body>
                      <Container fluid>
                        <Row>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t(
                                  'ENROLL_REMOVE_EMPLOYEE_TO_FROM_THE_PROJECT'
                                )}
                              </Card.Header>
                              <Card.Body>
                                {t(
                                  'ENROLL_EMPLOYEE_TO_THE_PROJECT_ENROLLMENT_LIST'
                                )}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='warning'
                                      onClick={() =>
                                        setEnrollEmployeeToProjectVisible(true)
                                      }
                                    >
                                      {t('ENROLL_REMOVE_EMPLOYEE')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col className='mb-2' sm={12} xl={6}>
                            <Card>
                              <Card.Header>
                                {t('CHANGE_GRADES_RATES')}
                              </Card.Header>
                              <Card.Body>
                                {t(
                                  'ADJUST_GRADE_RATES_TO_MATCH_CURRENT_STANDARDS'
                                )}
                                <Stack direction='horizontal'>
                                  <div className='p-2 ms-auto'></div>
                                  <div className='p-2'>
                                    <Button
                                      variant='warning'
                                      onClick={() =>
                                        setSelectProjectToChangeRates(true)
                                      }
                                    >
                                      {t('CHANGE_RATES')}
                                    </Button>
                                  </div>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </>
          )}
        </Row>
      </Container>
    </>
  )
}

export default AdministrationPage
