import React, { useState, useMemo } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  Employee,
  EmployeeWithRoles,
  Grade,
  Project,
} from '../../utils/LoginDTOS'

interface UpdateEmployeeGradeModalProps {
  visible: boolean
  toggleVisible: () => void
  onSubmit: (employeeId: number, gradeId: number) => void
  grades: Grade[]
  employees: Employee[]
}

const UpdateEmployeeGradeModal = (props: UpdateEmployeeGradeModalProps) => {
  const { visible, toggleVisible, onSubmit, grades, employees } = props

  const { t } = useTranslation()

  const [selectedGradeId, setSelectedGradeId] = useState<string>('')
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('')

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(Number(selectedEmployeeId), Number(selectedGradeId))
    setSelectedGradeId('')
    setSelectedEmployeeId('')
  }

  const selectedEmployee = useMemo<Employee>(() => {
    return (
      employees.find((employee) => employee?.id === +selectedEmployeeId) ??
      ({} as Employee)
    )
  }, [selectedEmployeeId, employees])

  const handleClose = () => {
    toggleVisible()
    setSelectedGradeId('')
    setSelectedEmployeeId('')
  }

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('UPDATE_GRADE_FOR_EMPLOYEE')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Text className='text-muted'>
            {t('UPDATE_GRADE_FOR_EMPLOYEE_DETAILS')}
          </Form.Text>
          <br />
          {selectedEmployeeId && (
            <Form.Text className='text-muted'>
              {t('CURRENT_GRADE') + ' ' + selectedEmployee.grade?.name}
            </Form.Text>
          )}
          <Form.Group controlId='formEmployee' className='mt-3'>
            <Form.Label>{t('SELECT_EMPLOYEE')}</Form.Label>
            <Form.Control
              as='select'
              value={selectedEmployeeId}
              onChange={(e) => {
                setSelectedEmployeeId(e.target.value)
                setSelectedGradeId('')
              }}
              required
            >
              <option value=''>...</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formGrade' className='mt-3'>
            <Form.Label>{t('SELECT_GRADE')}</Form.Label>
            <Form.Control
              as='select'
              value={selectedGradeId}
              onChange={(e) => setSelectedGradeId(e.target.value)}
              required
            >
              <option value=''>...</option>
              {grades.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('CLOSE')}
          </Button>
          <Button variant='primary' onClick={toggleVisible} type='submit'>
            {t('UPDATE')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default UpdateEmployeeGradeModal
