import React, { createContext, useContext, useState, useCallback } from 'react'

interface ToastData {
  id: number
  label: string
  value: string
  color: string
}

export type ToastContextType = {
  toasts: ToastData[]
  addToast: (label: string, values: string, color: string) => void
}

export const ToastContext = createContext<ToastContextType>({
  toasts: [],
  addToast: () => {},
})

export const useToast = () => useContext(ToastContext)

interface ToastProviderProps {
  children: React.ReactNode
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<ToastData[]>([])

  const addToast = useCallback(
    (
      label: string,
      value: string,
      color: string = '#0d6efd',
      delay = 15000
    ) => {
      const id = Date.now()
      const newToast: ToastData = { id, label, value, color } as ToastData
      setToasts((prevToasts: ToastData[]) => [...prevToasts, newToast])

      setTimeout(() => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
      }, delay)
    },
    []
  )

  const provider = { toasts, addToast }

  return (
    <ToastContext.Provider value={provider}>
      {children}
      <div
        className='toast-container position-fixed bottom-0 end-0 p-3'
        style={{ zIndex: 11 }}
      >
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className='toast show'
            role='alert'
            aria-live='assertive'
            aria-atomic='true'
          >
            <div className='toast-header'>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: toast.color,
                  borderRadius: '.3rem',
                  marginRight: '1em',
                }}
              ></div>
              <strong className='me-auto'>{toast.label}</strong>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='toast'
                aria-label='Close'
              ></button>
            </div>
            <div className='toast-body'>{toast.value}</div>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  )
}
