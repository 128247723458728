import { AxiosResponse } from 'axios';

export const downloadFile = (response: AxiosResponse): void => {
    const contentDisposition: string = response.headers['content-disposition']
    const filename = contentDisposition
      .split(';')[1]
      .trim()
      .split('=')[1]
      .replace(/"/g, '')

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }
