import { Currency, Grade } from './LoginDTOS';
import { API_PATHS } from './ApiUtils';
import axios, { AxiosResponse } from 'axios';

export interface GetProjectRateDTO {
  currency: Currency;
  ratePerHourByGradeId: Array<{ gradeId: number; rate: number }>;
}

async function getAllProjectRates(projectId: number): Promise<GetProjectRateDTO> {
  try {
      const response = await axios.get(API_PATHS.PROJECT_RATES.GET_ALL, {
          params: { projectId: projectId },
        })
        return response.data as GetProjectRateDTO;
  } catch (error: any) {
    throw error.response ? error.response.data : error;
  }
}

async function updateByGradeIdAndProjectId(newValue: number, gradeId: number, projectId: number, currencyId: number): Promise<boolean> {
  try {
      const response = await axios.post(API_PATHS.PROJECT_RATES.UPDATE_BY_GRADE_ID_AND_PROJECT_ID, {newValue, gradeId, projectId, currencyId})
        return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error;
  }
}

export { getAllProjectRates, updateByGradeIdAndProjectId }; 