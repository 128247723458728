import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom'
import LoginPage from './components/LoginPage'
import HomePage from './components/HomePage'
import AdministrationPage from './components/AdministrationPage'
import StartPage from './components/StartPage'
import ReportPage from './components/ReportPage'
import Navigation from './components/Navigation'
import FinanceInvoicesPage from './components/FinanceInvoicesPage'
import FinanceReportsPage from './components/FinanceReportsPage'
import ProfilePage from './components/ProfilePage'
import { IsLoggedInProvider } from './components/context/IsLoggedInContext.context'
import { ProjectsProvider } from './components/context/getProjects.context'
import { UserProjectsProvider } from './components/context/getUserProjects.context'
import CreateUserPage from './components/CreateUserPage'
import ChangeGradesRatesPage from './components/ChangeGradesRatesPage'
import HandleProjectPage from './components/HandleProjectPage'
import { EmployeesProvider } from './components/context/getEmployees.context'
import { CountriesProvider } from './components/context/getCountries.context'
import { CurrenciesProvider } from './components/context/getCurrencies.context'
import { GradesProvider } from './components/context/getGrade.context'
import { I18nProvider } from './components/context/i18n.context'
import { Spinner } from 'react-bootstrap'

function App() {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Router>
      <div>
        <I18nProvider>
          <IsLoggedInProvider>
            <ProjectsProvider>
              <UserProjectsProvider>
                <EmployeesProvider>
                  <CurrenciesProvider>
                    <GradesProvider>
                      <CountriesProvider
                        setIsLoadingFalse={() => setIsLoading(false)}
                      >
                        {isLoading ? (
                          <div
                            className='d-flex justify-content-center align-items-center'
                            style={{ height: '100vh' }}
                          >
                            <Spinner animation='border' role='status'>
                              <span className='visually-hidden'>
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        ) : (
                          <>
                            <Navigation />
                            <Switch>
                              <Route path='/start' element={<StartPage />} />
                              <Route path='/login' element={<LoginPage />} />
                              <Route path='/report' element={<ReportPage />} />
                              <Route
                                path='/administration'
                                element={<AdministrationPage />}
                              />
                              <Route
                                path='/finance/invoices'
                                element={<FinanceInvoicesPage />}
                              />
                              <Route
                                path='/finance/reports'
                                element={<FinanceReportsPage />}
                              />
                              <Route
                                path='/profile'
                                element={<ProfilePage />}
                              />
                              <Route
                                path='/createNewUser'
                                element={<CreateUserPage />}
                              />
                              <Route
                                path='/changeGradesRatesOnProject'
                                element={<ChangeGradesRatesPage />}
                              />
                              <Route
                                path='/handleProjects'
                                element={<HandleProjectPage />}
                              />
                              <Route path='/' element={<HomePage />} />
                            </Switch>
                          </>
                        )}
                      </CountriesProvider>
                    </GradesProvider>
                  </CurrenciesProvider>
                </EmployeesProvider>
              </UserProjectsProvider>
            </ProjectsProvider>
          </IsLoggedInProvider>
        </I18nProvider>
      </div>
    </Router>
  )
}

export default App
