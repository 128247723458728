import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Currency, Grade, Project } from '../utils/LoginDTOS'
import {
  getAllProjectRates,
  GetProjectRateDTO,
  updateByGradeIdAndProjectId,
} from '../utils/ProjectRatesUtils'
import { ToastTypes } from '../utils/ToastUtils'
import { useGradesContext } from './context/getGrade.context'
import { useProjectsContext } from './context/getProjects.context'
import { useToast } from './context/ToastContext.context'
import ChangeGradeRateModal from './modals/ChangeGradeRateModal'

const ChangeGradesRatesPage = () => {
  const { state } = useLocation()
  const { projectId } = state

  const { t } = useTranslation()

  const { addToast } = useToast()

  const { grades } = useGradesContext()
  const { projects } = useProjectsContext()

  const [projectRates, setProjectRates] = useState<GetProjectRateDTO>(
    {} as GetProjectRateDTO
  )
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [updatingRateValue, setUpdatingRateValue] = useState<number>(0)
  const [updatingCurrency, setUpdatingCurrency] = useState<Currency>(
    {} as Currency
  )
  const [updatingGrade, setUpdatingGrade] = useState<Grade>({} as Grade)

  const fetchAllProjectRates = async (projectId: number) => {
    setProjectRates(await getAllProjectRates(projectId))
  }

  useEffect(() => {
    fetchAllProjectRates(projectId)
  }, [projectId])

  const project = useMemo<Project>(() => {
    if (!projects) return {} as Project

    const project: Project | undefined = projects.find(
      (project) => project.id === projectId
    )

    return project ? project : ({} as Project)
  }, [projects, projectId])

  const getGradeByGradeId = useCallback(
    (gradeId: number): Grade => {
      if (!grades) return {} as Grade

      const grade: Grade | undefined = grades.find(
        (grade) => grade.id === gradeId
      )
      return grade ? grade : ({} as Grade)
    },
    [grades]
  )

  const handleChangeGradeRate = async (
    newValue: number,
    gradeId: number,
    projectId: number,
    currencyId: number
  ) => {
    const response = await updateByGradeIdAndProjectId(
      newValue,
      gradeId,
      projectId,
      currencyId
    )
    if (typeof response !== 'string') {
      addToast(t('SUCCESS'), t('RATE_HAS_BEEN_CHANGED'), ToastTypes.DEFAULT)
      fetchAllProjectRates(projectId)
    } else {
      addToast(t('FAILURE'), t('RATE_HAS_NOT_BEEN_CHANGED'), ToastTypes.ERROR)
    }
  }

  return (
    <Container className='page'>
      <ChangeGradeRateModal
        visible={modalOpened}
        toggleVisible={() => setModalOpened((prev) => !prev)}
        onSubmit={handleChangeGradeRate}
        currentValue={updatingRateValue}
        selectedCurrency={updatingCurrency}
        selectedGrade={updatingGrade}
        selectedProject={project}
      />
      {projectId && (
        <>
          <Row>
            <Col>
              {t('YOU_ARE_UPDATING_GRADE_RATES_FOR')} {project?.name}
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t('GRADE')}</th>
                    <th>{t('RATE_PER_HOUR')}</th>
                    <th>{t('UPDATE')}</th>
                  </tr>
                </thead>
                <tbody>
                  {projectRates.ratePerHourByGradeId !== undefined ? (
                    grades.map((grade: Grade) => (
                      <tr>
                        <td>{grade.id}</td>
                        <td>{getGradeByGradeId(grade.id).name}</td>
                        <td>
                          {+projectRates.ratePerHourByGradeId[+grade.id]}{' '}
                          {projectRates.currency.code}
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              setUpdatingRateValue(
                                +projectRates.ratePerHourByGradeId[+grade.id]
                              )
                              setUpdatingCurrency(projectRates.currency)
                              setUpdatingGrade(getGradeByGradeId(grade.id))
                              setModalOpened(true)
                            }}
                          >
                            {t('UPDATE')}
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>{t('LOADING_THREE_DOTS')}</p>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default ChangeGradesRatesPage
