// src/contexts/I18nContext.tsx

import React, { createContext, useContext, useEffect, useState } from 'react'
import i18n from '../../utils/i18n'

interface I18nContextProps {
  currentLanguage: string
  changeLanguage: (lng: string) => void
}

const I18nContext = createContext<I18nContextProps>({
  currentLanguage: i18n.language,
  changeLanguage: () => {},
})

export const useI18nContext = () => {
  return useContext(I18nContext)
}

interface I18nProviderProps {
  children: React.ReactNode
}

export const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
    setCurrentLanguage(lng)
  }

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setCurrentLanguage(lng)
    })

    return () => {
      i18n.off('languageChanged')
    }
  }, [])

  return (
    <I18nContext.Provider value={{ currentLanguage, changeLanguage }}>
      {children}
    </I18nContext.Provider>
  )
}
