import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Button, Tabs, Tab, Form } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'
import {
  CalendarValue,
  createDateHelperRange,
  isCalendarPieceArray,
} from '../utils/CalendarUtils'
import { downloadFile } from '../utils/FileUtils'
import { EmployeeWithRoles, Project, Role } from '../utils/LoginDTOS'
import {
  createReportOfEmployees,
  createReportOfEmployeesOnTheProjectBetweenDates,
  createReportOfOneEmployee,
} from '../utils/ReportsInvoicesUtils'
import { useEmployeesContext } from './context/getEmployees.context'
import { useProjectsContext } from './context/getProjects.context'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'

const FinanceReportsPage = () => {
  const { currentUser } = useIsLoggedInContext()
  const { projects } = useProjectsContext()
  const { employees } = useEmployeesContext()

  const { t } = useTranslation()

  const [key, setKey] = useState<string | null>('1')
  const [date, setDate] = useState<CalendarValue>([new Date(), new Date()])
  const [selectedProjectId, setSelectedProjectId] = useState<string>('')
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('')
  const [projectsToSelectFrom, setProjectsToSelectFrom] = useState<Project[]>(
    []
  )

  const projectsWithoutVacationAndIllness = useMemo(() => {
    return projects.filter(
      (project: Project) =>
        project.name !== 'Vacations' && project.name !== 'Illness'
    )
  }, [projects])

  const [employeesToSelectFrom, setEmployeesToSelectFrom] = useState<
    EmployeeWithRoles[]
  >([])

  const currentEmployeeWithRoles = useMemo<EmployeeWithRoles>(() => {
    return employees.filter((empl) => empl?.id === currentUser?.id)[0]
  }, [employees, currentUser])

  useEffect(() => {
    if (currentEmployeeWithRoles && currentEmployeeWithRoles.roles) {
      const isManager: boolean =
        currentEmployeeWithRoles.roles.filter(
          (role: Role) => role.name === 'manager'
        ).length > 0
      const isProjectManager: boolean =
        currentEmployeeWithRoles.roles.filter(
          (role: Role) => role.name === 'project_manager'
        ).length > 0

      if (isManager) {
        setProjectsToSelectFrom(projectsWithoutVacationAndIllness)
        setEmployeesToSelectFrom(employees)
      } else if (isProjectManager) {
        setProjectsToSelectFrom(
          projectsWithoutVacationAndIllness.filter(
            (project: Project) => project.projectManager?.id === currentUser?.id
          )
        )
      }
    }
  }, [currentEmployeeWithRoles, currentUser, projectsWithoutVacationAndIllness])

  const submitFirstForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const response = await createReportOfEmployeesOnTheProjectBetweenDates(
      +selectedProjectId,
      createDateHelperRange(date)
    )
    downloadFile(response)
  }

  const submitSecondForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const response = await createReportOfEmployees()
    downloadFile(response)
  }

  const submitThirdForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const response = await createReportOfOneEmployee(
      +selectedEmployeeId,
      createDateHelperRange(date)
    )
    downloadFile(response)
  }

  const refreshCalendar = () => {
    setDate([new Date(), new Date()])
  }

  useEffect(() => {
    console.log('date', date)
  }, [date])

  return (
    <Container className='page'>
      <Row>
        <Col sm={12}>
          <Tabs
            activeKey={key || 'profile'}
            onSelect={(k) => {
              setKey(k)
              refreshCalendar()
              setSelectedProjectId('')
              setSelectedEmployeeId('')
            }}
            id='uncontrolled-tab-example'
            className='mb-3'
          >
            <Tab
              eventKey='1'
              title={t('RAPORT_SKLADU_PRACOWNIKOW_NA_WYBRANYM_PROJEKCIE')}
            >
              <Row className='justify-content-md-center'>
                <Calendar selectRange onChange={setDate} value={date} />
                <Form onSubmit={submitFirstForm} className='my-3'>
                  <Form.Text className='text-muted'>
                    {t('SELECT_THE_PROJECT_TO_GENERATE_REPORT')}
                  </Form.Text>
                  <Form.Group controlId='formProject' className='mt-3'>
                    <Form.Label>{t('SELECT_PROJECT')}</Form.Label>
                    <Form.Control
                      as='select'
                      value={selectedProjectId}
                      onChange={(e) => setSelectedProjectId(e.target.value)}
                      required
                    >
                      <option value=''>...</option>
                      {projectsToSelectFrom.map((project: Project) => (
                        <option key={project.id} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Button
                    type='submit'
                    className='mt-3'
                    disabled={
                      !(
                        Number(selectedProjectId) > 0 &&
                        isCalendarPieceArray(date) &&
                        date[0] !== undefined &&
                        date[1] !== undefined
                      )
                    }
                  >
                    {t('GENERATE')}
                  </Button>
                </Form>
              </Row>
            </Tab>
            <Tab eventKey='2' title={t('RAPORT_SKLADU_FIRMY')}>
              <Row className='justify-content-md-center'>
                <Form onSubmit={submitSecondForm} className='my-3'>
                  <Form.Text className='text-muted'>
                    {t(
                      'AS_MANAGER_YOU_CAN_GENERATE_THE_REPORT_ABOUT_EVERY_EMPLOYEES'
                    )}
                  </Form.Text>
                  <br />
                  <Button type='submit' className='mt-3'>
                    {t('GENERATE')}
                  </Button>
                </Form>
              </Row>
            </Tab>
            <Tab eventKey='3' title={t('RAPORT_UZYTKOWNIKA')}>
              <Row className='justify-content-md-center'>
                <Calendar selectRange onChange={setDate} value={date} />
                <Form onSubmit={submitThirdForm} className='my-3'>
                  <Form.Text className='text-muted'>
                    {t('SELECT_EMPLOYEE_TO_GENERATE_THE_REPORT_FOR')}
                  </Form.Text>
                  <Form.Group controlId='formProject' className='mt-3'>
                    <Form.Label>{t('SELECT_EMPLOYEE')}</Form.Label>
                    <Form.Control
                      as='select'
                      value={selectedEmployeeId}
                      onChange={(e) => setSelectedEmployeeId(e.target.value)}
                      required
                    >
                      <option value=''>...</option>
                      {employeesToSelectFrom.map(
                        (employee: EmployeeWithRoles) => (
                          <option key={employee.id} value={employee.id}>
                            {employee.firstName + ' ' + employee.lastName}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Button
                    type='submit'
                    className='mt-3'
                    disabled={
                      !(
                        Number(selectedEmployeeId) > 0 &&
                        isCalendarPieceArray(date) &&
                        date[0] !== undefined &&
                        date[1] !== undefined
                      )
                    }
                  >
                    {t('GENERATE')}
                  </Button>
                </Form>
              </Row>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default FinanceReportsPage
