import React, { useState, useMemo } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'
import {
  CalendarValue,
  createDateHelperRange,
  isCalendarPieceArray,
} from '../utils/CalendarUtils'
import { downloadFile } from '../utils/FileUtils'
import { Project } from '../utils/LoginDTOS'
import { useProjectsContext } from './context/getProjects.context'
import { generateInvoiceForOneProject } from '../utils/ReportsInvoicesUtils'

const FinanceInvoicesPage = () => {
  const { t } = useTranslation()
  const { projects } = useProjectsContext()

  const [date, setDate] = useState<CalendarValue>([new Date(), new Date()])
  const [selectedProjectId, setSelectedProjectId] = useState<number>(-1)

  const isGenerateButtonActive = useMemo(() => {
    if (selectedProjectId < 0) return false

    if (isCalendarPieceArray(date)) {
      const [start, end] = date

      const startDate = start as Date
      const endDate = end as Date

      if (startDate === undefined || endDate === undefined) return false
      return true
    }
    return false
  }, [date, selectedProjectId])

  const projectsWithoutVacationAndIllness = useMemo(() => {
    return projects.filter(
      (project: Project) =>
        project.name !== 'Vacations' && project.name !== 'Illness'
    )
  }, [projects])

  const submitCreateInvoiceForm = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    const response = await generateInvoiceForOneProject(
      selectedProjectId,
      createDateHelperRange(date)
    )
    downloadFile(response)
  }
  return (
    <Container className='page'>
      <Form onSubmit={submitCreateInvoiceForm}>
        <Row>
          <Col>
            <Form.Text>{t('SELECT_THE_PROJECT_TO_GENERATE_INVOICE')}</Form.Text>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group controlId='formProject' className='mt-3'>
              <Form.Label>{t('SELECT_PROJECT')}</Form.Label>
              <Form.Control
                as='select'
                value={selectedProjectId}
                onChange={(e) => setSelectedProjectId(+e.target.value)}
                required
              >
                <option value=''>...</option>
                {projectsWithoutVacationAndIllness.map((project: Project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Calendar selectRange onChange={setDate} value={date} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}></Col>
          <Col sm={6}>
            <Button
              type='submit'
              className='mt-3'
              disabled={!isGenerateButtonActive}
            >
              {t('GENERATE')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default FinanceInvoicesPage
