import { API_PATHS } from './ApiUtils';
import axios, { AxiosResponse } from 'axios';

async function createNewBankHoliday(name: string, date: Date) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.BANK_HOLIDAY.POST_CREATE_NEW_BANK_HOLIDAY, { name, date });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

  async function getAllBankHolidays() {
    try {
      const response: AxiosResponse = await axios.get(API_PATHS.BANK_HOLIDAY.GET_ALL);
      return response.data as Date[];
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

export { createNewBankHoliday, getAllBankHolidays };

  