import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Image, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { isEmployee } from '../hooks/useIsLoggiedIn.hook'
import { DateHelper } from '../utils/CalendarUtils'
import { Employee, emptyEmployee } from '../utils/LoginDTOS'
import { getReportTimeByEmployeeId } from '../utils/ReportTimeUtils'
import { ReportTimeByEmployeeDTO } from '../utils/TimeReportDTOS'
import { useProjectsContext } from './context/getProjects.context'
import { useUserProjectsContext } from './context/getUserProjects.context'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'

const StartPage = () => {
  const [formValues, setFormValues] = useState<Employee>(emptyEmployee)
  const [historicalTimeReporting, setHistoricalTimeReporting] = useState<
    ReportTimeByEmployeeDTO[]
  >([])

  const { t } = useTranslation()

  const { currentUser } = useIsLoggedInContext()
  const { projects } = useProjectsContext()
  const { userProjects } = useUserProjectsContext()

  async function fetchReportTimeByEmployeeId(): Promise<void> {
    const startDate = new Date(new Date().setHours(0, 0, 0, 0))
    const endDate = new Date(new Date().setHours(23, 59, 59, 0))

    const getTimeReportingResponse = await getReportTimeByEmployeeId(
      currentUser && currentUser.id ? currentUser.id : 0,
      startDate,
      endDate
    )

    getTimeReportingResponse.forEach((arrayValue: ReportTimeByEmployeeDTO) =>
      arrayValue.dates.forEach(
        (dateHelper: DateHelper) =>
          (dateHelper.date = new Date(dateHelper.date))
      )
    )
    setHistoricalTimeReporting(getTimeReportingResponse)
  }

  useEffect(() => {
    fetchReportTimeByEmployeeId()
  }, [])

  useEffect(() => {
    refreshFormValues()
  }, [currentUser])

  const refreshFormValues = () => {
    if (isEmployee(currentUser)) {
      setFormValues({ ...currentUser, password: '' })
    }
  }

  const imageSrc: string = useMemo(() => {
    if (formValues.image === undefined) return '/img/placeholder-user.png'

    return formValues.image.split(',')[1] === 'null'
      ? '/img/placeholder-user.png'
      : formValues.image
  }, [formValues.image])

  return (
    <Container className='page'>
      <Row>
        <Col sm={3}>
          <Image style={{ width: '100%' }} src={imageSrc} roundedCircle fluid />
        </Col>
        <Col sm={9}>
          <h2 style={{ textAlign: 'center' }}>{t('MY_PROJECTS')}</h2>
          <p style={{ textAlign: 'center' }}>
            {userProjects.length > 0
              ? userProjects.map((project) => project.name).join(', ')
              : t('YOU_ARE_NOT_ALLOCATED_TO_ANY_PROJECT')}
          </p>
          {currentUser && (
            <>
              <h5 className='mt-5'>
                {currentUser.firstName} {currentUser.lastName}
              </h5>
              <p>{currentUser.grade?.name}</p>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h2 style={{ textAlign: 'center' }}>{t('TIME_REPORTED_TODAY')}</h2>
          {historicalTimeReporting.length > 0 ? (
            <Table className='mt-4' striped bordered hover>
              <thead>
                <tr>
                  <th>{t('PROJECT')}</th>
                  <th>{t('TIME')}</th>
                </tr>
              </thead>
              <tbody>
                {historicalTimeReporting.length > 0 &&
                  historicalTimeReporting.map((timeReport, index) => (
                    <tr key={index}>
                      <td>
                        {
                          projects.find(
                            (project) => project.id === timeReport.projectId
                          )?.name
                        }
                      </td>
                      <td>{timeReport.durationInHours}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            t('YOU_DO_NOT_HAVE_ANY_TIME_REPORTED_TODAY')
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default StartPage
