export interface LoginForm {
    login: string;
    password: string;
}

export interface JwtResponse {
    token: string;
}

export interface RegisterForm {
    firstName: string;
    middleName: string;
    lastName: string;
    login: string;
    password: string;
}

export interface Grade {
    id: number;
    name: string;
    code: string;
}

export interface Currency {
    id: number;
    name: string;
    code: string;
}

export interface Country {
    id: number;
    name: string;
    code: string;
    isInSchengen: boolean;
    currency?: Currency;
}

export interface Employee {
    id?: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    employeePhoto?: string;
    login: string;
    email?: string;
    password?: string;
    grade?: Grade;
    nationality?: Country;
    createdAt?: Date;
    image?: string;
}

export interface Role {
    id: number;
    name: string;
    description: string;
}

export interface EmployeeWithRoles extends Employee {
    roles: Role[];
}

export interface Project {
    id?: number,
    name: string,
    description: string,
    isInternal: boolean,
    originCountry: Country,
    projectManager?: Employee,
    legalName: string,
    address: string,
    phoneNumber: string,
    bankAccount: string
}

export const emptyEmployee: Employee = {
    firstName: '',
    middleName: '',
    login: '',
    lastName: '',
    email: '',
    password: '',
    image: '',
  }