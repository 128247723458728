import React, { useState, useEffect } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Project } from '../../utils/LoginDTOS'

interface UpdateProjectInvoicingDataModalProps {
  visible: boolean
  onCancel: () => void
  updatingProject: Project
  onSubmit: (projectData: Project) => void
  showBankAccount: boolean
}

const UpdateProjectInvoicingDataModal = (
  props: UpdateProjectInvoicingDataModalProps
) => {
  const [validated, setValidated] = useState(false)

  const { visible, onCancel, onSubmit } = props

  const { t } = useTranslation()

  const [projectData, setProjectData] = useState<Project>(props.updatingProject)

  useEffect(() => {
    if (visible) {
      setProjectData(props.updatingProject)
    }
  }, [visible, props.updatingProject])

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      setValidated(true)
      onSubmit(projectData)
      onCancel()
    }
  }

  return (
    <Modal show={visible} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t('UPDATE_INVOICING_DATA')}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={submitForm}>
        <Modal.Body>
          <Form.Label className='mt-2'>{t('LEGAL_NAME')}</Form.Label>
          <Form.Control
            required
            as='textarea'
            rows={1}
            placeholder={t('ENTER_LEGAL_NAME')}
            value={projectData.legalName}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                legalName: e.target.value,
              }))
            }
          />
          <Form.Label className='mt-2'>{t('ADDRESS')}</Form.Label>
          <Form.Control
            required
            as='textarea'
            rows={1}
            placeholder={t('ENTER_ADDRESS')}
            value={projectData.address}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                address: e.target.value,
              }))
            }
          />
          <Form.Label className='mt-2'>{t('PHONE_NUMBER')}</Form.Label>
          <Form.Control
            required
            as='textarea'
            rows={1}
            placeholder={t('ENTER_PHONE_NUMBER')}
            value={projectData.phoneNumber}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                phoneNumber: e.target.value,
              }))
            }
          />
          {props.showBankAccount && (
            <>
              <Form.Label className='mt-2'>{t('BANK_ACCOUNT')}</Form.Label>
              <Form.Control
                required
                as='textarea'
                rows={1}
                placeholder={t('ENTER_BANK_ACCOUNT')}
                value={projectData.bankAccount}
                onChange={(e) =>
                  setProjectData((prev) => ({
                    ...prev,
                    bankAccount: e.target.value,
                  }))
                }
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onCancel}>
            {t('CLOSE')}
          </Button>
          <Button variant='success' type='submit'>
            {t('UPDATE')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default UpdateProjectInvoicingDataModal
