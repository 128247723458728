import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { API_PATHS } from '../../utils/ApiUtils'
import { Grade } from '../../utils/LoginDTOS'

interface GradesContextProps {
  grades: Grade[]
}

const GradesContext = createContext<GradesContextProps>({ grades: [] })

export const useGradesContext = () => {
  return useContext(GradesContext)
}

interface GradesProviderProps {
  children: React.ReactNode
}

export const GradesProvider: React.FC<GradesProviderProps> = ({ children }) => {
  const [grades, setGrades] = useState<Grade[]>([])

  useEffect(() => {
    async function fetchGrades() {
      const response = await axios.get(API_PATHS.GRADE.GET_ALL)
      setGrades(
        response.data.map((grade: Grade) => ({
          ...grade,
        }))
      )
    }

    fetchGrades()
  }, [])

  return (
    <GradesContext.Provider value={{ grades: grades }}>
      {children}
    </GradesContext.Provider>
  )
}
