import React, { useState, useEffect } from 'react'
import { Col, Form, Button, Modal } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'
import {
  CalendarValue,
  isCalendarNotPieceArray,
} from '../../utils/CalendarUtils'

interface CreateBankHolidayModalProps {
  visible: boolean
  toggleVisible: () => void
  onSubmit: (name: string, date: Date) => void
}

const CreateBankHolidayModal = (props: CreateBankHolidayModalProps) => {
  const { visible, toggleVisible, onSubmit } = props

  const { t } = useTranslation()

  const [date, setDate] = useState<CalendarValue>(new Date())
  const [name, setName] = useState<string>('')

  useEffect(() => {
    setDate(new Date())
    setName('')
  }, [visible])

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isCalendarNotPieceArray(date)) {
      onSubmit(name, date)
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setName(event.target.value)
  }

  return (
    <Modal show={visible} onHide={toggleVisible}>
      <Modal.Header closeButton>
        <Modal.Title>{t('CREATE_BANK_HOLIDAY')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Text className='text-muted'>
            {t('SELECT_DATE_FOR_CREATING_A_NEW_BANK_HOLIDAY')}
          </Form.Text>
          <Calendar className='mt-3' onChange={setDate} value={date} />
          <Form.Group as={Col} controlId='formGridName'>
            <Form.Label>{t('BANK_HOLIDAY_NAME')}</Form.Label>
            <Form.Control
              type='name'
              placeholder={t('ENTER_BANK_HOLIDAY_NAME')}
              value={name}
              onChange={handleNameChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={toggleVisible}>
            {t('CLOSE')}
          </Button>
          <Button variant='primary' onClick={toggleVisible} type='submit'>
            {t('CREATE')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreateBankHolidayModal
