import React, { useCallback, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { login } from '../utils/LoginUtils'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'

const LoginPage = () => {
  const [loginVal, setLoginVal] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showError, setShowError] = useState(false)

  const { t } = useTranslation()

  const { loginContext } = useIsLoggedInContext()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'loginVal') setLoginVal(value)
    if (name === 'password') setPassword(value)
    if (name === 'firstName') setFirstName(value)
    if (name === 'lastName') setLastName(value)
  }

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      try {
        const loginResponse = await login(loginVal, password)
        loginContext(loginResponse)
      } catch (error) {
        setShowError(true)
      }
    },
    [firstName, lastName, loginVal, password]
  )

  return (
    <Container className='page'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <div className='mt-5'>
            <h2 className='text-center mb-4'>{t('LOGIN')}</h2>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='loginVal'>{t('LOGIN')}</label>
                <input
                  type='loginVal'
                  className='form-control'
                  id='loginVal'
                  placeholder={t('ENTER_LOGIN')}
                  name='loginVal'
                  value={loginVal}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className='form-group'>
                <label htmlFor='password'>{t('PASSWORD')}</label>
                <input
                  type='password'
                  className='form-control'
                  id='password'
                  placeholder={t('PASSWORD')}
                  name='password'
                  value={password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button type='submit' className='btn btn-primary mr-5 mt-3'>
                {t('LOGIN')}
              </button>
            </form>
            {showError && (
              <div className='alert alert-danger mt-3' role='alert'>
                {t('USER_NOT_FOUND_PLEASE_CHECK')}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default LoginPage
