import React, {
  ReactElement,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import {
  Col,
  Nav,
  Row,
  Tab,
  Container,
  Table,
  Form,
  Button,
  Modal,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import AlphabeticalProjectSelector from './common/AlphabeticalProjectSelector/AlphabeticalProjectSelector.component'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import {
  CalendarValue,
  DateHelper,
  createDateHelperRange,
  formatDate,
  isCalendarPieceArray,
} from '../utils/CalendarUtils'
import { useProjectsContext } from './context/getProjects.context'
import { useUserProjectsContext } from './context/getUserProjects.context'
import { Project } from '../utils/LoginDTOS'
import { ReportTimeByEmployeeDTO } from '../utils/TimeReportDTOS'
import { useIsLoggedInContext } from './context/IsLoggedInContext.context'
import {
  reportTime,
  getReportTimeByEmployeeId,
  deleteReportingTimeRow,
  UpdateReportTimeDTO,
  updateTimeReport,
  reportVacation,
  reportIllness,
  getAllIllness,
  getAllVacation,
} from '../utils/ReportTimeUtils'
import { useToast } from './context/ToastContext.context'
import { ToastTypes } from '../utils/ToastUtils'
import { useTranslation } from 'react-i18next'
import { getAllBankHolidays } from '../utils/BankHolidayUtils'

const ReportPage = () => {
  const { addToast } = useToast()

  const { i18n } = useTranslation()
  const { t } = useTranslation()

  const { projects } = useProjectsContext()
  const { userProjects } = useUserProjectsContext()
  const { currentUser } = useIsLoggedInContext()

  const [projectsToDisplay, setProjectsToDisplay] = useState<Project[]>([])
  const [choosenProjectId, setChoosenProjectId] = useState<number | undefined>(
    undefined
  )
  const [date, setDate] = useState<CalendarValue>([new Date(), new Date()])
  const [isAllProjectsActive, setIsAllProjectsActive] = useState<boolean>(false)
  const [timeSpent, setTimeSpent] = useState<number>(1)
  const [historicalTimeReporting, setHistoricalTimeReporting] = useState<
    ReportTimeByEmployeeDTO[]
  >([])

  const [showModal, setShowModal] = useState(false)
  const [updatingTimeReport, setUpdatingTimeReport] =
    useState<ReportTimeByEmployeeDTO | null>(null)
  const [updatingDate, setUpdatingDate] = useState<DateHelper | null>(null)

  const [isVacation, setIsVacation] = useState<boolean>(true)

  const [bankHolidaysDates, setBankHolidaysDates] = useState<Date[]>([])
  const [vacationDates, setVacationDates] = useState<Date[]>([])
  const [illnessDates, setIllnessDates] = useState<Date[]>([])

  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  const fetchAllBankHolidays = async () => {
    const responseData = await getAllBankHolidays()
    const bankHolidayDates = responseData.map(
      (dateAsString) => new Date(dateAsString)
    )
    setBankHolidaysDates(bankHolidayDates)
  }

  const fetchAllIllness = async () => {
    const responseData = await getAllIllness(
      currentUser && currentUser.id ? currentUser.id : 0
    )
    const illnessDates = responseData.map(
      (dateAsString) => new Date(dateAsString)
    )
    setIllnessDates(illnessDates)
  }

  const fetchAllVacation = async () => {
    const responseData = await getAllVacation(
      currentUser && currentUser.id ? currentUser.id : 0
    )
    const vacationDates = responseData.map(
      (dateAsString) => new Date(dateAsString)
    )
    setVacationDates(vacationDates)
  }

  useEffect(() => {
    const projectsWithoutVacationAndIllness = projects.filter(
      (project: Project) =>
        project.name !== 'Vacations' && project.name !== 'Illness'
    )
    setProjectsToDisplay(
      isAllProjectsActive ? projectsWithoutVacationAndIllness : userProjects
    )
    setChoosenProjectId(undefined)
  }, [isAllProjectsActive])

  useEffect(() => {
    setDate([new Date(), new Date()])
  }, [choosenProjectId])

  const choosenProject = useMemo<Project | undefined>(() => {
    if (typeof choosenProjectId === 'number') {
      return projects.find((obj) => obj.id === choosenProjectId)
    }
  }, [choosenProjectId])

  const selectedDates = useMemo<DateHelper[]>(() => {
    return createDateHelperRange(date)
  }, [date])

  const compareDatesByDay = (date1: Date, date2: Date): boolean => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  const isDateBankHoliday = useCallback(
    (selectedDate: Date) => {
      if (bankHolidaysDates.length === 0) return false
      return (
        bankHolidaysDates.find((date: Date) =>
          compareDatesByDay(date, selectedDate)
        ) !== undefined
      )
    },
    [bankHolidaysDates]
  )

  const isDateIllness = useCallback(
    (selectedDate: Date) => {
      if (illnessDates.length === 0) return false
      return (
        illnessDates.find((date: Date) =>
          compareDatesByDay(date, selectedDate)
        ) !== undefined
      )
    },
    [illnessDates]
  )

  const isDateVacation = useCallback(
    (selectedDate: Date) => {
      if (vacationDates.length === 0) return false
      return (
        vacationDates.find((date: Date) =>
          compareDatesByDay(date, selectedDate)
        ) !== undefined
      )
    },
    [vacationDates]
  )

  const shouldBlockReporting = useCallback(
    (selectedDate: Date) => {
      return (
        isDateBankHoliday(selectedDate) ||
        isDateIllness(selectedDate) ||
        isDateVacation(selectedDate)
      )
    },
    [isDateBankHoliday, isDateIllness, isDateVacation]
  )

  const submitTimeReport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const reportTimeDTO: ReportTimeByEmployeeDTO = {
      employeeId: currentUser && currentUser.id ? currentUser.id : 0,
      projectId: choosenProjectId ? choosenProjectId : 0,
      dates: selectedDates
        .filter((date) => !shouldBlockReporting(date.date))
        .filter((date) => !date.isWeekend),
      durationInHours: timeSpent,
    }

    const reportTimeResponse = await reportTime(reportTimeDTO)
    if (reportTimeResponse) {
      addToast(t('SUCCESS'), t('TIME_HAS_BEEN_REPORTED'), ToastTypes.DEFAULT)
    } else {
      addToast(t('FAILURE'), t('TIME_HAS_NOT_BEEN_REPORTED'), ToastTypes.ERROR)
    }

    setDate([new Date(), new Date()])
  }

  const reportTimeButtonDisabled = useMemo(() => {
    return (
      selectedDates
        .filter((date) => !shouldBlockReporting(date.date))
        .filter((date) => !date.isWeekend).length === 0
    )
  }, [shouldBlockReporting, selectedDates])

  async function fetchReportTimeByEmployeeId(
    dates: CalendarValue
  ): Promise<void> {
    if (isCalendarPieceArray(dates)) {
      const [start, end] = dates

      const startDate = start as Date
      const endDate = end as Date

      const getTimeReportingResponse = await getReportTimeByEmployeeId(
        currentUser && currentUser.id ? currentUser.id : 0,
        startDate,
        endDate
      )

      getTimeReportingResponse.forEach((arrayValue: ReportTimeByEmployeeDTO) =>
        arrayValue.dates.forEach(
          (dateHelper: DateHelper) =>
            (dateHelper.date = new Date(dateHelper.date))
        )
      )
      setHistoricalTimeReporting(getTimeReportingResponse)
    }
  }

  useEffect(() => {
    if (isCalendarPieceArray(date)) {
      fetchReportTimeByEmployeeId(date)
      fetchAllBankHolidays()
      fetchAllIllness()
      fetchAllVacation()
    }
  }, [date])

  const getNameFromArrayById = (
    allProjects: Project[],
    projectId: number
  ): string => {
    if (Array.isArray(allProjects)) {
      const name: string | undefined = allProjects.find(
        (el) => el.id === projectId
      )?.name
      return name ? name : ''
    }
    return ''
  }

  const deleteTimeReportingRow = async (
    employeeId: number | undefined,
    projectId: number,
    datePar: Date
  ) => {
    if (employeeId) {
      const deleteTimeReportingRowResponse = await deleteReportingTimeRow(
        projectId,
        currentUser && currentUser.id ? currentUser.id : 0,
        datePar
      )
      if (deleteTimeReportingRowResponse) {
        addToast(
          t('SUCCESS'),
          t('TIME_REPORT_HAS_BEEN_DELETED'),
          ToastTypes.DEFAULT
        )
        fetchReportTimeByEmployeeId(date)
      } else {
        addToast(
          t('FAILURE'),
          t('TIME_REPORT_HAS_NOT_BEEN_DELETED'),
          ToastTypes.ERROR
        )
      }
    }
  }

  const refreshCalendar = () => {
    setDate([new Date(), new Date()])
  }

  const handleUpdateClick = (
    element: ReportTimeByEmployeeDTO,
    date: DateHelper
  ) => {
    handleShowModal()
    setUpdatingTimeReport(element)
    setUpdatingDate(date)
  }

  const getUpdateButton = useCallback(
    (element: {
      date: DateHelper
      element: ReportTimeByEmployeeDTO
    }): ReactElement => {
      if (isDateIllness(element.date.date)) {
        return <>{t('CANNOT_MODIFY_HOURS_ON_ILLNESS')}</>
      }

      if (isDateVacation(element.date.date)) {
        return <>{t('CANNOT_MODIFY_HOURS_ON_VACATION')}</>
      }

      return (
        <Button
          variant='warning'
          onClick={() => handleUpdateClick(element.element, element.date)}
        >
          {t('UPDATE')}
        </Button>
      )
    },
    [isDateIllness, isDateVacation, handleUpdateClick, t]
  )

  const historicalTimeReportingAsTableRows = useMemo(() => {
    if (!historicalTimeReporting) return <></>
    const resultDateRows: ReactElement[] = []

    const sortedDates: {
      date: DateHelper
      element: ReportTimeByEmployeeDTO
    }[] = []

    historicalTimeReporting.forEach((element: ReportTimeByEmployeeDTO) => {
      element.dates.forEach((dateHelper: DateHelper) => {
        sortedDates.push({ date: dateHelper, element })
      })
    })

    sortedDates
      .sort((a, b) => a.date.date.getTime() - b.date.date.getTime())
      .forEach(
        (
          element: { date: DateHelper; element: ReportTimeByEmployeeDTO },
          index
        ) =>
          resultDateRows.push(
            <tr key={element.date.date.toString()}>
              <td>{index + 1}</td>
              <td>
                {getNameFromArrayById(projects, element.element.projectId)}
              </td>
              <td>{formatDate(element.date.date)}</td>
              <td>{element.date.dateName}</td>
              <td>{element.element.durationInHours}</td>
              <td>{getUpdateButton(element)}</td>
              <td>
                <OverlayTrigger
                  trigger='click'
                  placement='bottom'
                  overlay={
                    <Popover id='popover-basic'>
                      <Popover.Header as='h3'>
                        {t('CONFIRMATION')}
                      </Popover.Header>
                      <Popover.Body>
                        {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TIME_REPORT')}
                        <br />
                        <Button
                          variant='danger'
                          onClick={() =>
                            deleteTimeReportingRow(
                              currentUser?.id,
                              element.element.projectId,
                              element.date.date
                            )
                          }
                        >
                          {t('YES')}
                        </Button>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant='danger'>{t('DELETE')}</Button>
                </OverlayTrigger>
              </td>
            </tr>
          )
      )
    return resultDateRows
  }, [historicalTimeReporting])

  const reportVacationIllness = async () => {
    let response
    if (isVacation) {
      response = await reportVacation(
        currentUser && currentUser.id ? currentUser.id : 0,
        createDateHelperRange(date)
          .filter((date) => !date.isWeekend)
          .filter((date) => !shouldBlockReporting(date.date))
      )
    } else {
      response = await reportIllness(
        currentUser && currentUser.id ? currentUser.id : 0,
        createDateHelperRange(date).filter(
          (date) => !date.isWeekend && !shouldBlockReporting(date.date)
        )
      )
    }

    if (response) {
      addToast(
        t('SUCCESS'),
        t(`${isVacation ? 'VACATION' : 'ILLNESS'}_HAS_BEEN_REPORTED`),
        ToastTypes.DEFAULT
      )
      fetchReportTimeByEmployeeId(date)
    } else {
      addToast(
        t('FAILURE'),
        t(`${isVacation ? 'VACATION' : 'ILLNESS'}_HAS_NOT_BEEN_REPORTED`),
        ToastTypes.ERROR
      )
    }

    setDate([new Date(), new Date()])
  }

  const submitTimeReportUpdate = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    if (updatingDate && updatingTimeReport) {
      const updateReportTime: UpdateReportTimeDTO = {
        employeeId: currentUser && currentUser.id ? currentUser.id : 0,
        projectId: updatingTimeReport.projectId,
        date: updatingDate.date,
        durationInHours: updatingTimeReport.durationInHours,
      }
      const updateTimeReportResponse = await updateTimeReport(updateReportTime)
      if (updateTimeReportResponse) {
        addToast(
          t('SUCCESS'),
          t('TIME_REPORT_HAS_BEEN_UPDATED'),
          ToastTypes.DEFAULT
        )
        fetchReportTimeByEmployeeId(date)
      } else {
        addToast(
          t('FAILURE'),
          t('TIME_REPORT_HAS_NOT_BEEN_UPDATED'),
          ToastTypes.ERROR
        )
      }
    }
  }

  const selectedDatesAsString = useMemo<string>(() => {
    return createDateHelperRange(date)
      .filter((date) => !date.isWeekend)
      .filter((date) => !shouldBlockReporting(date.date))
      .map((date) => formatDate(date.date))
      .join(', ')
  }, [date])

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('UPDATE_TIME_REPORT')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitTimeReportUpdate}>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Control
                type='number'
                min={1}
                max={8}
                value={updatingTimeReport?.durationInHours}
                onChange={(e) =>
                  setUpdatingTimeReport(
                    (prevVal: ReportTimeByEmployeeDTO | null) => {
                      if (prevVal === null) {
                        return null
                      }
                      return {
                        ...prevVal,
                        durationInHours: Number(e.target.value),
                      }
                    }
                  )
                }
              />
              <Form.Text className='text-muted'>
                {t(
                  'YOU_HAVE_THE_ABILITY_TO_UPDATE_ONLY_THE_NUMBER_OF_THE_HOURS'
                )}
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseModal}>
              {t('CLOSE')}
            </Button>
            <Button variant='primary' onClick={handleCloseModal} type='submit'>
              {t('SAVE_CHANGES')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Container fluid className='page'>
        <Tab.Container defaultActiveKey='first'>
          <Row>
            <Col sm={2}>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='first' onClick={refreshCalendar}>
                    {t('REPORT_TIME_ON_THE_PROJECT')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='second' onClick={refreshCalendar}>
                    {t('HISTORICAL_REPORTING')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='third' onClick={refreshCalendar}>
                    {t('DAY_OFF_ILLNESS')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey='first'>
                  <Row>
                    <Col sm={3}>
                      <AlphabeticalProjectSelector
                        projects={projectsToDisplay}
                        setChoosenProjectId={setChoosenProjectId}
                        choosenProjectId={choosenProjectId}
                        isAllProjectsActive={isAllProjectsActive}
                        setIsAllProjectsActive={setIsAllProjectsActive}
                      />
                    </Col>
                    <Col sm={9}>
                      <Container fluid>
                        {choosenProjectId && (
                          <>
                            <Row>
                              <Calendar
                                selectRange
                                onChange={setDate}
                                value={date}
                              />
                            </Row>
                            <Row>
                              <Form
                                className='mt-5'
                                onSubmit={submitTimeReport}
                              >
                                <Col sm={8}>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      {t('SELECTED_PROJECT')}
                                    </Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder={t('SELECTED_PROJECT')}
                                      value={choosenProject?.name}
                                      disabled
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Label>
                                      {t('HOW_MUCH_TIME_TO_REPORT')}
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>{t('DATE')}</th>
                                            <th>{t('WEEKDAY')}</th>
                                            <th>{t('TIME_SPENT')}</th>
                                            <th>{t('COMMENT')}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {selectedDates
                                            .filter(
                                              (selectedDate) =>
                                                !selectedDate.isWeekend
                                            )
                                            .map((selectedDate, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {formatDate(
                                                    selectedDate.date
                                                  )}
                                                </td>
                                                <td>{selectedDate.dateName}</td>
                                                <td>
                                                  {shouldBlockReporting(
                                                    selectedDate.date
                                                  ) ? (
                                                    <p>{t('CANNOT_REPORT')}</p>
                                                  ) : (
                                                    <Form.Control
                                                      type='number'
                                                      min={1}
                                                      max={8}
                                                      value={timeSpent.toString()}
                                                      onChange={(e) =>
                                                        setTimeSpent(
                                                          Number(e.target.value)
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </td>
                                                <td>
                                                  {isDateBankHoliday(
                                                    selectedDate.date
                                                  )
                                                    ? t('BANK_HOLIDAY')
                                                    : isDateIllness(
                                                        selectedDate.date
                                                      )
                                                    ? t('ILLNESS')
                                                    : isDateVacation(
                                                        selectedDate.date
                                                      ) && t('VACATION')}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </Table>
                                      <Form.Text className='text-muted'>
                                        {t('THERE_IS_NO_WEEKEND_DAYS_INCLUDED')}
                                      </Form.Text>
                                    </Form.Label>
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group className='mb-3'>
                                    <Button
                                      type='submit'
                                      disabled={reportTimeButtonDisabled}
                                    >
                                      {t('REPORT_TIME')}
                                    </Button>
                                  </Form.Group>
                                </Col>
                              </Form>
                            </Row>
                          </>
                        )}
                      </Container>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey='second'>
                  <Row>
                    <Col sm={3}>
                      <Calendar selectRange onChange={setDate} value={date} />
                    </Col>
                    <Col sm={9}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{t('PROJECT')}</th>
                            <th>{t('DATE')}</th>
                            <th>{t('WEEKDAY')}</th>
                            <th>{t('TIME_SPENT')}</th>
                            <th>{t('UPDATE')}</th>
                            <th>{t('DELETE')}</th>
                          </tr>
                        </thead>
                        <tbody>{historicalTimeReportingAsTableRows}</tbody>
                      </Table>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey='third'>
                  <Container fluid>
                    <Row>
                      <Col sm={12}>
                        <DropdownButton
                          id='dropdown-basic-button'
                          title={t(isVacation ? 'VACATIONS' : 'ILLNESS')}
                        >
                          <Dropdown.Item
                            onClick={() => {
                              setIsVacation(true)
                              refreshCalendar()
                            }}
                          >
                            {t('VACATIONS')}
                          </Dropdown.Item>
                          <li>
                            <hr className='dropdown-divider' />
                          </li>
                          <Dropdown.Item
                            onClick={() => {
                              setIsVacation(false)
                              refreshCalendar()
                            }}
                          >
                            {t('ILLNESS')}
                          </Dropdown.Item>
                        </DropdownButton>
                      </Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col sm={3}>
                        <Calendar selectRange onChange={setDate} value={date} />
                      </Col>
                      <Col sm={9}>
                        <Form>
                          <Form.Group>
                            <Form.Label>
                              {t('YOU_ARE_REPORTING_FOR')}{' '}
                              <b>{t(isVacation ? 'VACATIONS' : 'ILLNESS')}</b>
                            </Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('SELECTED_DATES')}</Form.Label>
                            <b>{selectedDatesAsString}</b>
                          </Form.Group>
                          <Form.Group>
                            <Button
                              type='button'
                              onClick={reportVacationIllness}
                              disabled={reportTimeButtonDisabled}
                            >
                              {t(
                                'REPORT_' +
                                  (isVacation ? 'VACATIONS' : 'ILLNESS')
                              )}
                            </Button>
                          </Form.Group>
                          <Form.Text className='text-muted'>
                            {t('THERE_IS_NO_WEEKEND_DAYS_INCLUDED')}
                          </Form.Text>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  )
}

export default ReportPage
