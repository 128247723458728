import { API_PATHS } from './ApiUtils';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Country, Employee } from './LoginDTOS';

async function login(login: string, password: string) {
    try {
      const response: AxiosResponse = await axios.post(API_PATHS.AUTHENTICATION.LOGIN, { login, password });
      const user = {
        ...response.data.user,
        image: `data:image/jpeg;base64,${response.data.user.image}`,
      };
      return {...response.data, user};
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }
  
  async function checkLogin(login: string) {
    try {
      const response = await axios.post(API_PATHS.AUTHENTICATION.CHECK_LOGIN, { login });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }
  
  async function createNewUser(firstName: string, lastName: string, login: string, password: string) {
    try {
      const response = await axios.post(API_PATHS.EMPLOYEE.CREATE_NEW, {
        firstName,
        lastName,
        login,
        password
      });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error;
    }
  }

async function logout() {
    try {
        const response = await axios.get(API_PATHS.AUTHENTICATION.LOGOUT);
        return response.data;
    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
}

async function deleteUser(userId: number) {
    try {
        const response = await axios.delete(`${API_PATHS.AUTHENTICATION.DELETE_USER}/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
}

export interface UpdateUserDataDTO {
  id: number,
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  nationality: Country | undefined
}

async function updateUserData(employee: UpdateUserDataDTO) {
  try {
      const response = await axios.post(`${API_PATHS.EMPLOYEE.PUT_UPDATE_EMPLOYEE}`, employee)
      return response.data;
  } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
  }
}

export interface UpdatePasswordDTO {
  userId: number,
  oldPassword: string,
  newPassword: string
}

async function updatePassword(updatePasswordDTO: UpdatePasswordDTO) {
  try {
      const response = await axios.post(`${API_PATHS.EMPLOYEE.POST_UPDATE_PASSWORD}`, updatePasswordDTO)
      return response.data;
  } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
  }
}

async function updateImage(updateImageDTO: FormData) {
  try {
      const response = await axios.post(`${API_PATHS.EMPLOYEE.POST_UPDATE_IMAGE}`, updateImageDTO, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data;
  } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
  }
}

export { login, createNewUser, logout, deleteUser, checkLogin, updateUserData, updatePassword, updateImage };

  