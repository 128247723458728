import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { API_PATHS } from '../../utils/ApiUtils'
import { EmployeeWithRoles } from '../../utils/LoginDTOS'

interface EmployeesContextProps {
  employees: EmployeeWithRoles[]
  fetchEmployees: () => void
}

const EmployeesContext = createContext<EmployeesContextProps>({
  employees: [],
  fetchEmployees: () => {},
})

export const useEmployeesContext = () => {
  return useContext(EmployeesContext)
}

interface EmployeesProviderProps {
  children: React.ReactNode
}

export const EmployeesProvider: React.FC<EmployeesProviderProps> = ({
  children,
}) => {
  const [employees, setEmployees] = useState<EmployeeWithRoles[]>([])

  async function fetchEmployees() {
    const response = await axios.get(API_PATHS.EMPLOYEE.GET_ALL)
    setEmployees(
      response.data.map((user: EmployeeWithRoles) => ({
        ...user,
        image: `data:image/jpeg;base64,${user.image}`,
      }))
    )
  }

  useEffect(() => {
    fetchEmployees()
  }, [])

  return (
    <EmployeesContext.Provider value={{ employees, fetchEmployees }}>
      {children}
    </EmployeesContext.Provider>
  )
}
