import React, { useState, useMemo, useEffect } from 'react'
import {
  Form,
  Accordion,
  Button,
  Badge,
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Project } from '../../../utils/LoginDTOS'

interface AlphabeticalProjectSelectorProps {
  projects: Project[]
  setChoosenProjectId: (id: number | undefined) => void
  choosenProjectId: number | undefined
  setIsAllProjectsActive: (newValue: boolean) => void
  isAllProjectsActive: boolean
}

const AlphabeticalProjectSelector = (
  props: AlphabeticalProjectSelectorProps
) => {
  const { projects, setChoosenProjectId, choosenProjectId } = props

  const { t } = useTranslation()

  const alphabet = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  )

  const firstLetters = useMemo<Record<string, Project[]> | null>(() => {
    if (projects === undefined || projects.length === 0) {
      return null
    }

    const object: Record<string, Project[]> = {}
    projects.forEach((project: Project) => {
      const firstLetter: string = project.name.slice(0, 1).toUpperCase()
      if (object[firstLetter] === undefined) {
        object[firstLetter] = [project]
      } else {
        object[firstLetter].push(project)
      }
    })
    return object
  }, [projects])

  const accordion = useMemo(() => {
    if (firstLetters === null) return <></>

    return alphabet.map((letter, index) => {
      if (firstLetters[letter] === undefined) return <></>

      return (
        <Accordion.Item eventKey={'' + index} key={index}>
          <Accordion.Header>
            {letter}
            <Badge pill style={{ marginLeft: '1rem' }} bg='secondary'>
              {firstLetters[letter].length}
            </Badge>
          </Accordion.Header>
          <Accordion.Body>
            <Container fluid>
              <Row>
                {firstLetters[letter].map((project: Project) => (
                  <Col sm={12} index={project.id}>
                    <Button
                      style={{ width: '100%', marginBottom: '.5em' }}
                      variant={
                        project.id === choosenProjectId ? 'secondary' : 'light'
                      }
                      onClick={() =>
                        setChoosenProjectId(
                          project.id === choosenProjectId
                            ? undefined
                            : project.id
                        )
                      }
                    >
                      {project.name}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      )
    })
  }, [alphabet, firstLetters])

  return (
    <div>
      <Form>
        <Form.Switch
          id='flexSwitchCheckDefault'
          label={t('SHOW_ALL_PROJECTS')}
          value={props.isAllProjectsActive ? 'true' : 'false'}
          onChange={(e) => props.setIsAllProjectsActive(e.target.checked)}
        />
      </Form>
      {projects.length !== 0 ? (
        <Accordion defaultActiveKey='0'>{accordion}</Accordion>
      ) : (
        <h6 className='text-center mt-5'>
          {t('THERE_IS_NO_PROJECT_ASSIGNED')}
          <br />
          {t('TRY_SWITCHING_TO_ALL_PROJECTS')}
        </h6>
      )}
    </div>
  )
}

export default AlphabeticalProjectSelector
