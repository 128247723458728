import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { API_PATHS } from '../../utils/ApiUtils'
import { Project } from '../../utils/LoginDTOS'

interface ProjectsContextProps {
  projects: Project[]
  fetchProjects: () => void
}

const ProjectsContext = createContext<ProjectsContextProps>({
  projects: [],
  fetchProjects: () => {},
})

export const useProjectsContext = () => {
  return useContext(ProjectsContext)
}

interface ProjectsProviderProps {
  children: React.ReactNode
}

export const ProjectsProvider: React.FC<ProjectsProviderProps> = ({
  children,
}) => {
  const [projects, setProjects] = useState<Project[]>([])

  async function fetchProjects() {
    const response = await axios.get(API_PATHS.PROJECT.GET_ALL)
    setProjects(response.data)
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <ProjectsContext.Provider value={{ projects, fetchProjects }}>
      {children}
    </ProjectsContext.Provider>
  )
}
