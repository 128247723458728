import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Country, Employee, Project } from '../../utils/LoginDTOS'
import { useCountriesContext } from '../context/getCountries.context'
import { useEmployeesContext } from '../context/getEmployees.context'

interface CreateNewProjectProps {
  visible: boolean
  toggleVisible: () => void
  onSubmit: (projectData: Project) => void
}

const CreateNewProjectModal = (props: CreateNewProjectProps) => {
  const [validated, setValidated] = useState(false)

  const { employees } = useEmployeesContext()
  const { countries } = useCountriesContext()

  const { visible, toggleVisible, onSubmit } = props

  const { t } = useTranslation()

  const [projectData, setProjectData] = useState<Project>({} as Project)

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      setValidated(true)
      onSubmit(projectData)
      toggleVisible()
    }
  }

  return (
    <Modal show={visible} onHide={toggleVisible}>
      <Modal.Header closeButton>
        <Modal.Title>{t('CREATE_NEW_PROJECT')}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={submitForm}>
        <Modal.Body>
          <Form.Text className='text-muted'>
            {t('CREATE_NEW_PROJECT_HERE')}
          </Form.Text>
          <br />
          <Form.Text style={{ backgroundColor: 'yellow', borderRadius: '5px' }}>
            {t(
              'BE_CAREFUL_NEW_PROJECT_WILL_HAVE_DEFAULT_GRADES_GENERATED_AUTOMATICALLY'
            )}
          </Form.Text>

          <Form.Label className='mt-4'>{t('PROJECT_NAME')}</Form.Label>
          <Form.Control
            required
            type='name'
            placeholder={t('ENTER_PROJECT_NAME')}
            value={projectData.name}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
          <Form.Label className='mt-2'>{t('PROJECT_DESCRIPTION')}</Form.Label>
          <Form.Control
            as='textarea'
            rows={6}
            placeholder={t('ENTER_PROJECT_DESCRIPTION')}
            value={projectData.description}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
          <Form.Label className='mt-2'>{t('ORIGIN_COUNTRY')}</Form.Label>
          <Form.Control
            required
            as='select'
            value={projectData?.originCountry?.id}
            onChange={(e) => {
              const newCountry: Country | undefined = countries.find(
                (country) => country.id === +e.target.value
              )
              setProjectData((prev) => ({
                ...prev,
                originCountry:
                  newCountry !== undefined ? newCountry : ({} as Country),
              }))
            }}
          >
            <option value=''>...</option>
            {countries.map((country: Country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </Form.Control>
          <Form.Label className='mt-2'>{t('PROJECT_MANAGER')}</Form.Label>
          <Form.Control
            required
            as='select'
            value={
              projectData?.projectManager !== undefined &&
              projectData?.projectManager.id !== undefined
                ? projectData.projectManager.id
                : ''
            }
            onChange={(e) => {
              const newEmployee: Employee | undefined = employees.find(
                (employee) => employee.id === +e.target.value
              )
              setProjectData((prev) => ({
                ...prev,
                projectManager:
                  newEmployee !== undefined ? newEmployee : ({} as Employee),
              }))
            }}
          >
            <option value=''>...</option>
            {employees.map((employee: Employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.firstName + ' ' + employee.lastName}
              </option>
            ))}
          </Form.Control>
          <Form.Label className='mt-2'>{t('LEGAL_NAME')}</Form.Label>
          <Form.Control
            required
            as='textarea'
            rows={1}
            placeholder={t('ENTER_LEGAL_NAME')}
            value={projectData.legalName}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                legalName: e.target.value,
              }))
            }
          />
          <Form.Label className='mt-2'>{t('ADDRESS')}</Form.Label>
          <Form.Control
            required
            as='textarea'
            rows={1}
            placeholder={t('ENTER_ADDRESS')}
            value={projectData.address}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                address: e.target.value,
              }))
            }
          />
          <Form.Label className='mt-2'>{t('PHONE_NUMBER')}</Form.Label>
          <Form.Control
            required
            as='textarea'
            rows={1}
            placeholder={t('ENTER_PHONE_NUMBER')}
            value={projectData.phoneNumber}
            onChange={(e) =>
              setProjectData((prev) => ({
                ...prev,
                phoneNumber: e.target.value,
              }))
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={toggleVisible}>
            {t('CLOSE')}
          </Button>
          <Button variant='success' type='submit'>
            {t('CREATE')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreateNewProjectModal
