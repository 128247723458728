import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container fluid className='homepage width-100wh'>
        <div className='home_biglabel'>
          <h1 className='py-5 px-5 biglabel'>Reportify</h1>
        </div>
      </Container>
      <Container>
        <Row className='homepage'>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Image
                  fluid
                  alt='calendar_clock'
                  src='img/calendar_clock.png'
                />
              </Col>
              <Col sm={6} className='d-flex align-items-center'>
                <p>
                  {t('REPORT_WORKING_TIME_INCLUDING_PROJECTS_AND_ACTIVITIES')}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className='d-flex align-items-center'>
                <p>
                  {t('GENERATING_INVOICES_BASED_ON_COLLECTED_DATA_OVER_TIME')}
                </p>
              </Col>
              <Col sm={6}>
                <Image
                  fluid
                  alt='document_money'
                  src='img/document_money.png'
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Image fluid alt='graph' src='img/graph.png' />
              </Col>
              <Col sm={6} className='d-flex align-items-center'>
                <p>
                  {t('GENERATING_REPORTS_ON_WORKING_TIME_AND_RESOURCES_HUMAN')}
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Image
              fluid
              alt='home_page_computer'
              src='img/home_page_computer.png'
            />
          </Col>
        </Row>
        <Row className='homepage'>
          <Col sm={4}></Col>
          <Col sm={4}>
            {t('THE_REPORTIFY_WEB_APPLICATION_WAS_CREATED_TO_DELIVER')}
          </Col>
          <Col sm={4}></Col>
        </Row>
        <Row className='homepage mb-5 justify-content-center'>
          <Col sm={6} className='d-lg-flex flex-direction-row'>
            <div className='image-container'>
              <Image alt='placeholder-user' src='img/Anya.png' />
              <div>
                <p>Anna Pomorska s22524</p>
              </div>
            </div>
            <div className='image-container'>
              <Image alt='placeholder-user' src='img/Mykhailo.jpg' />
              <div>
                <p>Mykhailo Pomorskyi s22525</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default HomePage
