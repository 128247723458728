import React, { useState } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { useToast } from './context/ToastContext.context'
import { createNewUser } from '../utils/LoginUtils'
import { ToastTypes } from '../utils/ToastUtils'
import { useEmployeesContext } from './context/getEmployees.context'
import { useTranslation } from 'react-i18next'

const CreateUserPage = () => {
  const { addToast } = useToast()
  const { fetchEmployees } = useEmployeesContext()

  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    login: '',
    password: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const createUser = await createNewUser(
      formData.firstName,
      formData.lastName,
      formData.login,
      formData.password
    )
    if (createUser) {
      addToast(t('SUCCESS'), t('USER_HAS_BEEN_CREATED'), ToastTypes.DEFAULT)
      setFormData({
        firstName: '',
        lastName: '',
        login: '',
        password: '',
      })
      fetchEmployees()
    } else {
      addToast(t('FAILURE'), t('USER_HAS_NOT_BEEN_CREATED'), ToastTypes.ERROR)
    }
  }

  return (
    <Container className='page'>
      <Row className='justify-content-md-center'>
        <Col md='6'>
          <h2>{t('REGISTER')}</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='formFirstName'>
              <Form.Label>{t('FIRST_NAME')}</Form.Label>
              <Form.Control
                type='text'
                name='firstName'
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='formLastName'>
              <Form.Label>{t('LAST_NAME')}</Form.Label>
              <Form.Control
                type='text'
                name='lastName'
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='formLogin'>
              <Form.Label>{t('LOGIN')}</Form.Label>
              <Form.Control
                type='text'
                name='login'
                value={formData.login}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='formPassword'>
              <Form.Label>{t('PASSWORD')}</Form.Label>
              <Form.Control
                type='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant='primary' type='submit' className='mt-2'>
              {t('REGISTER')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CreateUserPage
