import React, { createContext, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useIsLoggedIn from '../../hooks/useIsLoggiedIn.hook'
import { Employee } from '../../utils/LoginDTOS'

interface IsLoggedInContextType {
  isLoggedIn: boolean
  loginContext: (token: string) => void
  logoutContext: () => void
  currentUser: Employee | null
  refreshCurrentUserInCookies: (employee: Employee) => void
}

const IsLoggedInContext = createContext<IsLoggedInContextType | undefined>(
  undefined
)

export const useIsLoggedInContext = () => {
  const context = useContext(IsLoggedInContext)
  if (!context) {
    throw new Error(
      'useIsLoggedInContext must be used within a IsLoggedInProvider'
    )
  }
  return context
}

interface IsLoggedInProviderProps {
  children: React.ReactNode
}

export const IsLoggedInProvider: React.FC<IsLoggedInProviderProps> = ({
  children,
}) => {
  const {
    isLoggedIn,
    loginContext,
    logoutContext,
    currentUser,
    refreshCurrentUserInCookies,
  } = useIsLoggedIn()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (
      !isLoggedIn &&
      location.pathname !== '/' &&
      location.pathname !== '/login'
    ) {
      navigate('/')
    }
  }, [location.pathname, isLoggedIn])

  return (
    <IsLoggedInContext.Provider
      value={{
        isLoggedIn,
        loginContext,
        logoutContext,
        currentUser,
        refreshCurrentUserInCookies,
      }}
    >
      {children}
    </IsLoggedInContext.Provider>
  )
}
